import {
  Observable
} from 'rxjs';
import {
  IEmployeeDto,
  IEmployeeRequestDto,
  IPaginatedEmployeeDto
} from './index';
import {
  IEmployeeStateUpdateDto
} from './IEmployeeStateUpdateDto';
import {
  IEmployeeDetailDto
} from './iemployee-detail.dto';
import {
  ISearchTermsDto
} from '../search/isearch-terms.dto';
import {
  IResetUserPasswordsDto
} from './IResetUserPasswordsDto';
import {
  IEmployeeCreateOrUpdateDto
} from '../employee-create/i-employee-create-or-update.dto';
import {
  ILdapResponseDto
} from '../ildapResponse.dto';
import {
  IEmployeeUpdateDto
} from './iemployee-update.dto';
import {
  IEmployeeCreateBulkRequestDto
} from './iemployee-import.dto';
import {
  IEmployeeBulkCreateResponseDto
} from './iemployee-bulk-create-response.dto';
import {
  IEmployeeBulkDeleteResponseDto
} from './iemployee-bulk-delete-response.dto';
import {
  IBaseLocationDto
} from '../location';
import {
  IlocationMinimalDto
} from '../location/ilocation-minimal.dto';
import {
  IEmployeeSpecializationDto
} from './iemployee-specialization.dto';

export abstract class IEmployeeApi {
  abstract getEmployees(requestDto: IEmployeeRequestDto): Observable<IPaginatedEmployeeDto>;
  abstract isGlobalAdmin(): Observable<boolean>;
  abstract updateEmployeesState(stateUpdate: IEmployeeStateUpdateDto): Observable<ILdapResponseDto>;
  abstract getEmployee(employeeNumber: string): Observable<IEmployeeDetailDto>;
  abstract GetCurrentEmployee(): Observable<IEmployeeDto>;
  abstract GetCurrentEmployeeMainLocations(searchTerm: string): Observable<IBaseLocationDto[]>;
  abstract search(term: string): Observable<ISearchTermsDto>;
  abstract searchFiltered(requestDto: IEmployeeRequestDto): Observable<ISearchTermsDto>;
  abstract createEmployees(requestDto: IEmployeeCreateBulkRequestDto): Observable<IEmployeeBulkCreateResponseDto>;
  abstract resetUsersPasswords(requestDto: IResetUserPasswordsDto): Observable<boolean>;
  abstract createEmployee(employee: IEmployeeCreateOrUpdateDto): Observable<ILdapResponseDto>;
  abstract updateEmployee(employee: IEmployeeUpdateDto): Observable<ILdapResponseDto>;
  abstract deleteEmployees(ids: string[]): Observable<IEmployeeBulkDeleteResponseDto>;
  abstract getLocationsWithSpecialization(requestParams: IEmployeeSpecializationDto): Observable<IlocationMinimalDto[]>;
}
