import {
  Observable,
  OperatorFunction
} from 'rxjs';
import {
  ILocationBulkDeleteResponseDto
} from './ilocation-bulk-delete-response.dto';
import {
  ILocationDto
} from './ilocation.dto';
import {
  ISearchTermsDto
} from '../search/isearch-terms.dto';
import {
  IModifyLocationInput
} from './imodifyLocationInput';
import {
  ILdapResponseDto
} from '../ildapResponse.dto';
import {
  ILocationRequestDto
} from './ILocationRequestDto';
import {
  IPaginationResponseDto
} from '../pagination/IPaginationResponseDto';
import {
  ILocationDetailDto
} from './ilocation-detail.dto';
import {
  IexceptionHandlerService
} from '../exception/iexception-handler.service';
import {
  ILocationTableService
} from './ilocation-table.service';
import {
  LocationDetailEditData,
  PmInputData
} from 'hagebau-coremedia';
import {
  PmInputDataPayload
} from '../../app/location/location-details.component';
import {
  IMainLocationDto
} from './imain-location.dto';
import {
  FilterData
} from 'hagebau-coremedia';
import {
  IlocationMinimalDto
} from './ilocation-minimal.dto';
import {
  ValidationException
} from '../../network/api/http/api-exception';
import {
  LocationSortKeys
} from './ILocationSortDto';
import {
  ISearchService
} from '../search/isearch.service';
import {
  IBaseLocationDto
} from './ilocationBase.dto';
import {
  IModifyMainLocationInput
} from './imodifyMainLocationInput';
import {
  IMainLocationHistoryRequestDto
} from './IMainLocationHistoryRequestDto';
import {
  IMainLocationHistoryDto
} from './IMainLocationHistoryDto';
import {
  IRetentionRateImportDto
} from './ilocation-import.dto';
import {
  IRetentionRateBulkUpdateResponseDto
} from './iretention-rate-bulk-update-response.dto';

export abstract class ILocationService extends IexceptionHandlerService {
  abstract getList(requestDto: ILocationRequestDto): Observable<IPaginationResponseDto<ILocationDto>>;

  abstract getSupplierList(requestDto: ILocationRequestDto): Observable<IPaginationResponseDto<ILocationDto>>;

  abstract getListWithoutDummy(requestDto: ILocationRequestDto, distinctKey: keyof ILocationDto): Observable<ILocationDto[]>;

  abstract getMainLocationListWithoutDummy(requestDto: ILocationRequestDto): Observable<IMainLocationDto[]>;

  abstract delete(ids: string[]): Observable<ILocationBulkDeleteResponseDto>;

  abstract search(term: string): Observable<ISearchTermsDto>;

  abstract searchForAll(term: string): Observable<IlocationMinimalDto[]>;

  abstract searchFiltered(requestDto: ILocationRequestDto): Observable<ISearchTermsDto>;

  abstract update(modifyInput: IModifyLocationInput): Observable<ILdapResponseDto>;

  abstract getLocation(locationNumber: string): Observable<ILocationDetailDto>;

  abstract GetChildLocationsForSecurity(mainLocationCn: string): Observable<IlocationMinimalDto[]>;

  abstract getTableStructure(): ILocationTableService;

  abstract getSearchService(): ISearchService;

  abstract preProcessSearchInput(minSearchLength: number): OperatorFunction<string, string>;

  abstract convertSearchResultsFromTerms(termsDto: ISearchTermsDto): PmInputData[];

  abstract convertSearchResultsFromLocationDto(searchResults: IMainLocationDto[]): PmInputDataPayload<IMainLocationDto>[];

  abstract createModifyInput(result: ILocationDetailDto, locationDetail: ILocationDetailDto): IModifyLocationInput;

  abstract isDummyData(locations: ILocationDto[]): boolean;

  abstract createNumberSearchRequestDto(filterSnippet: FilterData, filterIndex: number): ILocationRequestDto;

  abstract updateLocationsState(ids: string[], isActive: boolean): Observable<ILdapResponseDto>;

  abstract updateMainLocationEmailsAndDomains(update: IModifyMainLocationInput): Observable<ILdapResponseDto>;

  abstract updateRetentionRates(rates: IRetentionRateImportDto[]): Observable<IRetentionRateBulkUpdateResponseDto>;

  abstract getTranslateError(validationException: ValidationException): string;

  abstract createSearchRequestDtoForFilterSuggestions(filterSnippet: FilterData): ILocationRequestDto;

  abstract mapToFilterSuggestions(searchResult: ILocationDto[], valueField: keyof ILocationDto): PmInputData[];

  abstract mapMainLocationToFilterSuggestions(searchResult: IMainLocationDto[], valueField: keyof IMainLocationDto): PmInputData[];

  abstract convertSearchResults(termsDto: ISearchTermsDto): PmInputData[];

  abstract mapSortKey(eventSortKey: string): LocationSortKeys;

  abstract isLocationBasicDataModified(updatedLocation: LocationDetailEditData, existingLocation: ILocationDetailDto): boolean;

  abstract getListWithSimplifiedLocations(requestDto: ILocationRequestDto, distinctKey: keyof ILocationDto): Observable<IBaseLocationDto[]>;

  abstract getListWithSimplifiedLocationsIgnoringRoles(requestDto: ILocationRequestDto, distinctKey: keyof ILocationDto): Observable<IBaseLocationDto[]>;

  abstract isSupplierLocation(hgbNumber: string): boolean;

  abstract getMainLocationHistory(requestDto: IMainLocationHistoryRequestDto): Observable<IPaginationResponseDto<IMainLocationHistoryDto>>;
}
