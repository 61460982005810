import {
  ColumnNames,
  Filter,
  PaginationControlData,
  PmCheckboxFieldData,
  PmCheckboxState,
  PmCheckboxType,
  PmInputData,
  ExitType,
  LocationDetailEditData
} from 'hagebau-coremedia';
import {
  ILocationDetailDto
} from './ilocation-detail.dto';

export const COUNTRY_OPTIONS: PmInputData[] = [
  {
    key: '0',
    value: 'DE'
  },
  {
    key: '1',
    value: 'CH'
  },
  {
    key: '2',
    value: 'AT'
  },
  {
    key: '3',
    value: 'BE'
  },
  {
    key: '4',
    value: 'BG'
  },
  {
    key: '5',
    value: 'DK'
  },
  {
    key: '6',
    value: 'EE'
  },
  {
    key: '7',
    value: 'FI'
  },
  {
    key: '8',
    value: 'FR'
  },
  {
    key: '9',
    value: 'GR'
  },
  {
    key: '10',
    value: 'IE'
  },
  {
    key: '11',
    value: 'IT'
  },
  {
    key: '12',
    value: 'HR'
  },
  {
    key: '13',
    value: 'LV'
  },
  {
    key: '14',
    value: 'LT'
  },
  {
    key: '15',
    value: 'LU'
  },
  {
    key: '16',
    value: 'MT'
  },
  {
    key: '17',
    value: 'NL'
  },
  {
    key: '18',
    value: 'PL'
  },
  {
    key: '19',
    value: 'PT'
  },
  {
    key: '20',
    value: 'RO'
  },
  {
    key: '21',
    value: 'SE'
  },
  {
    key: '22',
    value: 'SK'
  },
  {
    key: '23',
    value: 'SI'
  },
  {
    key: '24',
    value: 'ES'
  },
  {
    key: '25',
    value: 'CZ'
  },
  {
    key: '26',
    value: 'HU'
  },
  {
    key: '27',
    value: 'CY'
  },
];

export const DEFAULT_COLUMN_NAMES: ColumnNames = {
  checkBoxColumn: '',
  numberColumn: $localize`number|Number`,
  descriptionColumn: $localize`description|Description`,
  branchColumn: $localize`branch|Branch`,
  mainLocationColumn: $localize`mainLocation|Main Location`,
  contractsColumn: $localize`contracts|contracts`,
  employeeCountColumn: $localize`employeeCount|Employees`,
  delAdminCountColumn: $localize`delAdminCount|Del.Admins`,
  actionColumn: '',
};

export const PAGINATION: PaginationControlData = {
  current: 1,
  nextAmount: 2,
  previousAmount: 0,
  resultRange: '1-10',
  resultTotal: '500',
  nextDisabled: false,
  prevDisabled: true
};

export const PAGE_SIZES: PmInputData[] = [
  {
    key: '1',
    value: '10'
  },
  {
    key: '2',
    value: '20'
  },
  {
    key: '3',
    value: '50'
  }
];

export const FILTERS: Filter[] = [
  {
    filterId: 0,
    name: $localize`number|Number`,
    identifier: 'number',
    text: '',
    filterSuggestions: [],
    filterSuggestionsVisible: false,
    isDropdown: false
  },
  {
    filterId: 1,
    name: $localize`description|Description`,
    identifier: 'description',
    text: '',
    filterSuggestions: [],
    filterSuggestionsVisible: false,
    isDropdown: false
  },
  {
    filterId: 2,
    name: $localize`branch|Branch`,
    identifier: 'branch',
    text: '',
    filterSuggestions: [],
    filterSuggestionsVisible: false,
    isDropdown: false
  },
  {
    filterId: 3,
    name: $localize`mainLocation|Main Location`,
    identifier: 'mainLocation',
    text: '',
    filterSuggestions: [],
    filterSuggestionsVisible: false,
    isDropdown: false
  }
];

export const DEFAULT_LOCATION_DETAIL_DATA: ILocationDetailDto = {
  cn: '',
  number: '',
  description: '',
  branch: '',
  salesChannel: '',
  mainLocation: null,
  isSupplier: false,
  street: '',
  postCode: '',
  city: '',
  region: '',
  country: '',
  telephone: '',
  fax: '',
  email: '',
  shortName: '',
  website: '',
  tradeId: '',
  gln: '',
  searchTopic: '',
  clerkName: '',
  clerkEmail: '',
  clerkTelephone: '',
  clerkFax: '',
  clerkDepartment: '',
  clerkPurchaseGroup: '',
  clerkPurchaseName: '',
  supplierClassification: '',
  purchaseDepartment: '',
  purchaseGroup: '',
  assortment: '',
  legallyIndependentZ000025: false,
  delAdmins: [],
  contracts: [],
  assignedAllianceCns: [],
  isActive: false,
  centralDeletionFlag: false,
  eventITKNVVPRAT2: false,
  shouldBeShownInKba: false,
  shouldBeShownInDlb: false,
  shouldBeShownInLv: false,
  shouldBeShownInGv: false,
  contactPersons: '',
  allowedEmailDomains: [],
  allowedEmailAddresses: []
};

export const ACTIVE_BOX: PmCheckboxFieldData = {
  label: $localize`Active|Active`,
  state: PmCheckboxState.DEFAULT,
  disabled: true,
  type: PmCheckboxType.INPUT
};

export const SUPPLIER_BOX: PmCheckboxFieldData = {
  label: $localize`isSupplier|Supplier`,
  state: PmCheckboxState.DEFAULT,
  disabled: true,
  type: PmCheckboxType.INPUT
};

export const LEGALLY_INDEPENDENT_BOX_Z000025: PmCheckboxFieldData = {
  label: $localize`legallyIndependentZ000025|Legally Independent Z000025`,
  state: PmCheckboxState.DEFAULT,
  disabled: true,
  type: PmCheckboxType.INPUT
};

export const CENTRAL_DELETION_FLAG_BOX: PmCheckboxFieldData = {
  label: $localize`centralDeletionFlag|Central Deletion Flag`,
  state: PmCheckboxState.DEFAULT,
  disabled: true,
  type: PmCheckboxType.INPUT
};

export const EVENT_IT_BOX: PmCheckboxFieldData = {
  label: $localize`eventIt|EventIt`,
  state: PmCheckboxState.DEFAULT,
  disabled: true,
  type: PmCheckboxType.INPUT
};

export const SHOULD_BE_SHOWN_IN_KBA_BOX: PmCheckboxFieldData = {
  label: $localize`shouldBeShownInKba|Shown in KBA`,
  state: PmCheckboxState.DEFAULT,
  disabled: true,
  type: PmCheckboxType.INPUT
}

export const SHOULD_BE_SHOWN_IN_DLB_BOX: PmCheckboxFieldData = {
  label: $localize`shouldBeShownInDlb|Shown in DLB`,
  state: PmCheckboxState.DEFAULT,
  disabled: true,
  type: PmCheckboxType.INPUT
}

export const SHOULD_BE_SHOWN_IN_LV_BOX: PmCheckboxFieldData = {
  label: $localize`shouldBeShownInLv|Shown in LV`,
  state: PmCheckboxState.DEFAULT,
  disabled: true,
  type: PmCheckboxType.INPUT
}

export const SHOULD_BE_SHOWN_IN_GV_BOX: PmCheckboxFieldData = {
  label: $localize`shouldBeShownInGv|Shown in GV`,
  state: PmCheckboxState.DEFAULT,
  disabled: true,
  type: PmCheckboxType.INPUT
}

export const LOCATION_DETAIL_EDIT_DATA_DEFAULT: LocationDetailEditData = {
  basicInfo: [],
  viewOnlyInfo: [],
  singleLineInfo: [],
  contracts: [],
  isSupplier: false,
  legallyIndependentZ000025: false,
  centralDeletionFlag: false,
  eventITKNVVPRAT2: false,
  exitMode: ExitType.CANCEL,
  routeBackToTableBtn: false,
  isActive: false,
  shouldBeShownInKba: false,
  shouldBeShownInDlb: false,
  shouldBeShownInLv: false,
  shouldBeShownInGv: false
}
