import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  CreateEmployeeApplicationsColumnNames,
  IMultiselectFieldData,
  PmMultiSelectInputData,
  Row,
  SearchInputEvent,
  WizardHeaderStepElement,
  TextCell,
  AlertType,
  Cell,
  CellType,
  MultiSelectType,
  MultiselectCell
} from 'hagebau-coremedia';
import {
  EMPLOYEE_CREATE_STEP_IDENTIFIERS
} from '../employee-create.component';
import {
  IAppPermissionService
} from '../../../services/appPermission/iappPermission.service';
import {
  IAppPermissionMinimalDto
} from '../../../services/appPermission/IAppPermissionMinimalDto';
import {
  ValidationException
} from '../../../network/api/http/api-exception';
import {
  IExceptionTranslatorService
} from '../../../services/exception/iexception-translator.service';
import {
  IBaseLocationDto,
  ILocationFilterDto,
  ILocationService,
  LocationSortKeys,
} from '../../../services/location';
import {
  WithLoading,
  WithRx,
  HgbLoadingKeys,
} from '../../mixins';
import {
  IEmployeeAppPermissionDto
} from '../../../services/appPermission/IEmployeeAppPermissionDto';
import {
  ApplicationName
} from '../../../services/appPermission/application-name.enum';
import {
  IEmployeeCreateService
} from '../../../services/employee-create/iemployee-create.service';
import {
  IlocationMinimalDto
} from '../../../services/location/ilocation-minimal.dto';
import {
  map,
  switchMap
} from 'rxjs/operators';
import {
  LdapSortDirection
} from '../../../services/LdapSortDirection';
import {
  IPageFacadeService
} from '../../../services/ipage-facade';
import {
  IEmployeeService
} from '../../../services/employee';
import {
  combineLatest,
  iif,
  Observable,
  of,
  Subject,
  Subscription
} from 'rxjs';
import {
  AppSettings
} from '../../../services/appSettings/appSettings';

const SELECTED_LOCATIONS_CELL_ID: string = 'selectedLocationsCell';
const MAXIMUM_PAGE_SIZE: number = 500;
const PAGE: number = 1;
const LOCATIONS_DROPDOWN_CELL_ID: string = 'locationsDropdownCell';
const APP_LOCATION_SEPARATOR: string = ', ';
const MARKETING_PROFIT_READ: string = 'marketing_profit.Read';
const SUPPLIER_DIRECTORY_READ: string = 'supplier_directory.Read';

type LocationSearchResponseBundle = {
  locationResponse: IlocationMinimalDto[],
  searchInput: SearchInputEvent
}

@Component({
  selector: 'employee-create-applications-step',
  templateUrl: './employee-create-applications-step.component.html',
})
export class EmployeeCreateApplicationsStepComponent extends WithRx(WithLoading()) implements OnChanges {
  private readonly employeeCreateService: IEmployeeCreateService;
  private readonly appPermissionService: IAppPermissionService;
  private readonly exceptionTranslatorService: IExceptionTranslatorService;
  private readonly locationService: ILocationService;
  private readonly employeeService: IEmployeeService;
  private readonly pageFacade: IPageFacadeService;

  stepIdentifier: number = EMPLOYEE_CREATE_STEP_IDENTIFIERS.STEP_APPLICATIONS;
  rows: Row[] = [];
  columnNames: CreateEmployeeApplicationsColumnNames = {
    checkBoxColumn: '',
    nameColumn: $localize`employee-name|Name`,
    locationsColumn: $localize`locations|locations`,
    selectedLocationsColumn: $localize`selected-locations|selected-locations`,
  };
  selectedAppPermissions: IEmployeeAppPermissionDto[] = [];
  appPermissions: IAppPermissionMinimalDto[] = [];
  selectAllCheckbox: PmMultiSelectInputData = {
    key: $localize`selectAll|Select all`,
    value: $localize`selectAll|Select all`,
    checked: false
  }
  locations: IlocationMinimalDto[] = [];
  initialLocations: IlocationMinimalDto[] = [];
  previouslySelectedLocations: Map<number, string[]> = new Map();
  selectedRows: Row[] = [];
  locationsIgnoringRoles: IlocationMinimalDto[] = [];
  locationsWithSpecializations: string[] = [];

  //rxjs
  searchPreviewSubject$: Subject<SearchInputEvent> = new Subject<SearchInputEvent>();
  searchPreviewSubscription: Subscription = new Subscription();

  constructor(
    employeeCreateService: IEmployeeCreateService,
    appPermissionService: IAppPermissionService,
    exceptionTranslatorService: IExceptionTranslatorService,
    locationService: ILocationService,
    employeeService: IEmployeeService,
    pageFacade: IPageFacadeService,
  ) {
    super();
    this.employeeCreateService = employeeCreateService;
    this.appPermissionService = appPermissionService;
    this.exceptionTranslatorService = exceptionTranslatorService;
    this.locationService = locationService;
    this.employeeService = employeeService;
    this.pageFacade = pageFacade;
  }

  @Input()
    wizardHeaderSteps: WizardHeaderStepElement[] = [];

  @Input()
    currentCreationStep: number = 0;

  @Input()
    initialPermissions: IEmployeeAppPermissionDto[] = [];

  @Output()
    onBackClicked: EventEmitter<number> = new EventEmitter<number>();

  @Output()
    onNextClicked: EventEmitter<number> = new EventEmitter<number>();

  @Output()
    onAppPermissionsUpdated: EventEmitter<IEmployeeAppPermissionDto[]> = new EventEmitter<IEmployeeAppPermissionDto[]>();

  /**
   * @ignore
   */
  readonly HgbLoadingKeys: typeof HgbLoadingKeys = HgbLoadingKeys;

  ngOnInit() {
    this.activateSearchPreviewSubject();
  }

  // triggers table build when applications step is shown -> to get current data from previous steps
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['currentCreationStep'] && changes['currentCreationStep'].currentValue === EMPLOYEE_CREATE_STEP_IDENTIFIERS.STEP_APPLICATIONS) {
      this.setApplicationTableRows();
    }
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.searchPreviewSubscription.unsubscribe();
  }

  onNextButtonClicked(){
    this.onNextClicked.emit(this.stepIdentifier);
    this.createAndEmitEmployeeAppPermissions();
  }

  createAndEmitEmployeeAppPermissions() {
    this.onAppPermissionsUpdated.emit(this.selectedAppPermissions);
  }

  onRowMultiselectChange(multiSelectField: IMultiselectFieldData) {
    this.updatePreviouslySelected(multiSelectField);
    const selectedRowIndex: number = this.rows.findIndex(row => multiSelectField.fieldName.includes(row.rowId));
    this.rows = this.employeeCreateService.getTableStructure().mapSelectedAppLocationsToRows(this.rows, selectedRowIndex, this.previouslySelectedLocations);
    this.selectedRows = this.filterSelectedRows(selectedRowIndex);
    this.locations.push(...this.locationsIgnoringRoles)
    this.selectedAppPermissions = this.employeeCreateService.getTableStructure().mapSelectedRowsToAppPermissions(this.selectedRows, this.locations);

    // update MP row in case of KBA change
    if (multiSelectField.fieldName === SUPPLIER_DIRECTORY_READ){
      this.updateDropdownOptionsForRow(MARKETING_PROFIT_READ);
      const selectedLocationsMP: string[] = this.getSelectedLocationsByRowId(MARKETING_PROFIT_READ);
      const selectedLocationsKBA: string[] = this.getSelectedLocationsByRowId(SUPPLIER_DIRECTORY_READ);
      const filteredLocationCnsMP: string[] = selectedLocationsMP.filter(location => selectedLocationsKBA.includes(location));
      const filteredLocationsMP: IlocationMinimalDto[] = this.locations.filter(location => filteredLocationCnsMP.includes(location.cn));
      const marketingProfitRow: Row | undefined = this.rows.find(row => row.rowId === MARKETING_PROFIT_READ);
      const selectedRows: Row[] = marketingProfitRow ? [marketingProfitRow] : [];
      const marketingProfitRowIndex: number = this.rows.findIndex(row => MARKETING_PROFIT_READ.includes(row.rowId));
      this.selectedAppPermissions = this.employeeCreateService.getTableStructure().mapSelectedRowToAppPermissions(
        selectedRows, filteredLocationsMP, MARKETING_PROFIT_READ, this.selectedAppPermissions);
      this.rows = this.employeeCreateService.getTableStructure().mapSelectedAppLocationsToRows(this.rows, marketingProfitRowIndex, this.previouslySelectedLocations);
    }
  }

  filterSelectedRows(selectedRowIndex: number): Row[] {
    this.selectedRows = this.rows;
    if (!this.selectedRows.some(row => row === this.rows[selectedRowIndex])) {
      this.selectedRows.push(this.rows[selectedRowIndex]);
    }
    const selectedLocationsCellIndex: number = this.rows[selectedRowIndex].cells.findIndex(cell => cell.cellId && cell.cellId.includes(SELECTED_LOCATIONS_CELL_ID));
    return this.selectedRows.filter(row => (row.cells[selectedLocationsCellIndex] as TextCell).text !== '');
  }

  public updatePreviouslySelected(modifiedMultiselect: IMultiselectFieldData) {
    const selectedRowIndex: number = this.rows.findIndex(row => modifiedMultiselect.fieldName.includes(row.rowId));
    let selectedOptions: string[] = this.previouslySelectedLocations.get(selectedRowIndex) ?? [];
    selectedOptions = this.addCheckedOptions(selectedOptions, modifiedMultiselect.options);
    selectedOptions = this.removeUncheckedOptions(selectedOptions, modifiedMultiselect.options);

    this.previouslySelectedLocations.set(selectedRowIndex, selectedOptions);

    // update MP selected Options in case of KBA change
    if (modifiedMultiselect.fieldName.includes(SUPPLIER_DIRECTORY_READ)) {
      const marketingProfitRowIndex: number = this.rows.findIndex(row => row.rowId === MARKETING_PROFIT_READ);
      let marketingProfitSelectedOptions: string[] = this.previouslySelectedLocations.get(marketingProfitRowIndex) ?? [];
      marketingProfitSelectedOptions = marketingProfitSelectedOptions.filter(option => selectedOptions.includes(option));
      this.previouslySelectedLocations.set(marketingProfitRowIndex, marketingProfitSelectedOptions);
    }
  }


  private getSelectedLocationsByRowId(rowId: string): string[] {
    const appPermissionRow: Row | undefined = this.rows.find(row => row.rowId === rowId);
    if (appPermissionRow) {
      const selectedLocationsCell: Cell | undefined = appPermissionRow.cells.find(cell => cell.cellId && cell.cellId.includes(SELECTED_LOCATIONS_CELL_ID));
      const selectedLocations: string[] = (selectedLocationsCell as TextCell).text.split(APP_LOCATION_SEPARATOR).map(
        (hgbNumber: string) => this.initialLocations.find(location => location.number === hgbNumber)?.cn || '');
      return selectedLocations;
    }
    return [];
  }


  private addCheckedOptions(selectedOptions: string[], options: PmMultiSelectInputData[]): string[] {
    return selectedOptions.concat(options
      .filter((option: PmMultiSelectInputData) => option.checked && !selectedOptions
        .includes(option.value))
      .map(option => option.value));
  }

  private removeUncheckedOptions(selectedOptions: string[], options: PmMultiSelectInputData[]): string[] {
    return selectedOptions.filter((selectedOption: string) => {
      const foundOption: PmMultiSelectInputData | undefined = options.find(option => option.value === selectedOption);
      return !(foundOption && foundOption.value === selectedOption && !foundOption.checked);
    });
  }

  onLocationSearchInput(searchInputData: SearchInputEvent) {
    this.searchPreviewSubject$.next(searchInputData);
  }

  private setApplicationTableRows() {
    const loadables: HgbLoadingKeys[] = [HgbLoadingKeys.LOAD_PERMISSIONS];
    this.appPermissionService.getList()
      .pipe(
        this.useLoadingAnimation(...loadables),
        this.catchValidationException(this.translateError.bind(this)),
        map((result: IAppPermissionMinimalDto[]) => this.filterAppPermissionsList(result)),
      )
      .subscribe(appPermissions => {
        const sortedAppPermissions: IAppPermissionMinimalDto[]  = this.sortAppPermissions(appPermissions);
        this.setLocationDropdownOptions(sortedAppPermissions);
        this.initializeAppLocations(sortedAppPermissions);
      });
  }

  private initializeAppLocations(appPermissions: IAppPermissionMinimalDto[]) {
    const loadables: HgbLoadingKeys[] = [HgbLoadingKeys.LOAD_PERMISSION_LOCATIONS]

    const editableLocationsObs: Observable<IBaseLocationDto[]> = this.fetchEditableLocations(loadables);
    const existingLocationsObs: Observable<IBaseLocationDto[]> = this.fetchExistingLocations(loadables);

    combineLatest([editableLocationsObs, existingLocationsObs])
      .subscribe((locationData: [IBaseLocationDto[], IBaseLocationDto[]]) => {
        this.locations = Array.from(locationData[0]).map(location => this.mapLocationToMinimalDto(location));
        this.initialLocations = Array.from(locationData[0]).map(location => this.mapLocationToMinimalDto(location));
        this.locationsIgnoringRoles = Array.from(locationData[1]).map(location => this.mapLocationToMinimalDto(location));
        this.assignInitialPermissions(appPermissions);
        this.initializeDropdownOptions(this.locations, this.rows);
      });
  }

  private fetchEditableLocations(loadables: HgbLoadingKeys[]): Observable<IBaseLocationDto[]> {
    return this.locationService.getListWithSimplifiedLocations({
      Filters: this.createRequestFilter(),
      SearchTerms: [],
      SearchFields: [],
      SortParams: {
        SortKey: LocationSortKeys.None,
        SortDirection: LdapSortDirection.Ascending
      },
      Pagination: {
        Page: PAGE,
        PageAmount: MAXIMUM_PAGE_SIZE
      }
    }, 'cn')
      .pipe(
        this.useLoadingAnimation(...loadables),
      );
  }

  private fetchExistingLocations(loadables: HgbLoadingKeys[]): Observable<IBaseLocationDto[]> {
    return this.locationService.getListWithSimplifiedLocationsIgnoringRoles({
      Filters: this.createRequestFilter(),
      SearchTerms: [],
      SearchFields: [],
      SortParams: {
        SortKey: LocationSortKeys.None,
        SortDirection: LdapSortDirection.Ascending
      },
      Pagination: {
        Page: PAGE,
        PageAmount: MAXIMUM_PAGE_SIZE
      }
    }, 'cn')
      .pipe(
        this.useLoadingAnimation(...loadables),
      );
  }

  private initializeDropdownOptions(locations: IlocationMinimalDto[], rows: Row[]) {
    const initialRowIds: string[] = rows.map(row => row.rowId);
    const initialHgbNumbers: string[] = locations.map(location => location.number);
    initialRowIds.forEach(rowId => {
      this.employeeCreateService.getTableStructure().mapLocationsToRow(initialHgbNumbers, rowId, '', rows, false);
    })
    this.setMarketingProfitRow();
  }

  private setMarketingProfitRow(): void {
    this.employeeCreateService.getTableStructure().getMarketingProfitLocations(this.rows, this.previouslySelectedLocations, '')
      .pipe(
        map(loc => loc.map(location => location.number))
      ).subscribe((locationNumbers) => {
        //set options for marketing profit
        this.employeeCreateService.getTableStructure().setMarketingProfitRow(locationNumbers, '', this.rows, false);
      });
  }

  private updateDropdownOptionsForRow(rowId: string): void {
    const selectedRowIndex: number = this.rows.findIndex(row => rowId.includes(row.rowId));
    const selectedCellIndex: number = this.rows[selectedRowIndex].cells.findIndex(cell => cell.cellId && cell.cellId.includes(LOCATIONS_DROPDOWN_CELL_ID));
    this.rows[selectedRowIndex].cells[selectedCellIndex] = {
      options: [],
      placeholder: '',
      text: '',
      fieldName: rowId,
      type: CellType.MULTISELECT_CELL,
      multiselectType: MultiSelectType.SEARCH,
      dropfilterVisible: true,
      cellId: LOCATIONS_DROPDOWN_CELL_ID,
    } as MultiselectCell

    const locations: string[] = this.initialLocations.map(location => location.number);
    this.employeeCreateService.getTableStructure().mapLocationsToRow(locations, rowId, '', this.rows, false);
    this.setMarketingProfitRow();
  }

  private assignInitialPermissions(appPermissionsOfEditor: IAppPermissionMinimalDto[]) {
    this.rows.forEach(row => {
      appPermissionsOfEditor.push({
        permission: row.rowId
      })
    });

    appPermissionsOfEditor.forEach(appPermission => {
      const preSelectedPermission: IEmployeeAppPermissionDto | undefined = this.initialPermissions.find(initPermission => {
        return initPermission.AppCn === appPermission.permission;
      })
      if (preSelectedPermission !== undefined) {
        const combinedLocations: IlocationMinimalDto[] = [...this.locationsIgnoringRoles, ...this.locations];
        this.selectedAppPermissions.push({
          AppCn: appPermission.permission,
          LocationCns: preSelectedPermission.LocationCns.map(
            hgbNumber => combinedLocations.find(location => location.number === hgbNumber)?.cn || '')
        });
        const selectedRowIndex: number = this.rows.findIndex(row => appPermission.permission === row.rowId);
        this.previouslySelectedLocations.set(selectedRowIndex, preSelectedPermission.LocationCns);
      }
    });
  }

  private createRequestFilter(): ILocationFilterDto {
    const locationCns: string[] =  this.initialPermissions.map(permission => permission.LocationCns).flatMap(
      permission => permission);
    return {
      Cn: [],
      Branch: [],
      Contract: [],
      Description: [],
      MainLocation: [],
      Number: locationCns.map(locationCn => {
        return {
          Value: locationCn,
          IsNotFilter: false
        }
      })
    }
  }

  private activateSearchPreviewSubject() {
    const loadables: HgbLoadingKeys[] = [HgbLoadingKeys.LOAD_PERMISSION_LOCATIONS_FROM_SEARCH]
    const processSearchInputObservable: Observable<SearchInputEvent> = this.searchPreviewSubject$
      .pipe(
        this.locationService.getSearchService().preProcessSearchInputEvent(AppSettings.MIN_SEARCH_LENGTH_DEFAULT),
        this.useLoadingAnimation(...loadables));
    const fetchLocationsObservable: Observable<IlocationMinimalDto[]> = processSearchInputObservable.pipe(
      switchMap(input =>
        iif(() => input.rowId === MARKETING_PROFIT_READ,
          of(input).pipe(
            this.switchMapCancelable(searchInput => this.employeeCreateService.getTableStructure().getMarketingProfitLocations(this.rows, this.previouslySelectedLocations, searchInput.text)),
          ),
          of(input).pipe(
            map(searchInput => searchInput.text),
            this.switchMapCancelable(this.locationService.searchForAll.bind(this.locationService))
          )
        )
      ),
    );
    combineLatest([processSearchInputObservable, fetchLocationsObservable]).pipe(
      map(combinedValues => this.mapToLocationSearchResponseBundle(combinedValues[1], combinedValues[0]))
    ).subscribe((response: LocationSearchResponseBundle) => {
      this.loading.LOAD_PERMISSION_LOCATIONS_FROM_SEARCH = false;
      const hgbNumbers: string[] = response.locationResponse.map(location => location.number);
      this.locations.push(...response.locationResponse);
      if (response.searchInput.rowId === MARKETING_PROFIT_READ) {
        this.employeeCreateService.getTableStructure().setMarketingProfitRow(hgbNumbers, response.searchInput.text, this.rows, true)
        return;
      }
      this.rows = this.employeeCreateService.getTableStructure().mapLocationsToRow(hgbNumbers, response.searchInput.rowId,
        response.searchInput.text, this.rows, true);
    },
    error => {
      this.loading.LOAD_PERMISSION_LOCATIONS_FROM_SEARCH = false;
      this.pageFacade.emitAlert({
        label: error.message ? error.message : $localize`Data Loading Error|Couldn't load data.`,
        alertType: AlertType.ERROR
      });
    });
  }

  private mapToLocationSearchResponseBundle(locationResponse: IlocationMinimalDto[], searchInput: SearchInputEvent): LocationSearchResponseBundle {
    return {
      locationResponse: locationResponse,
      searchInput: searchInput
    }
  }

  private sortAppPermissions(appPermissions: IAppPermissionMinimalDto[]): IAppPermissionMinimalDto[]{
    const serviceBonusReadIndex: number = appPermissions.findIndex(permission => permission.permission === 'service_bonus.Read');
    const serviceBonusWriteIndex: number = appPermissions.findIndex(permission => permission.permission === 'service_bonus.Write');

    const kbaIndex: number = appPermissions.findIndex(permission => permission.permission === 'supplier_directory.Read');
    const marketingProfitIndex: number = appPermissions.findIndex(permission => permission.permission === 'marketing_profit.Read');
    let rearrangedResult: IAppPermissionMinimalDto[] = [...appPermissions];
    if (marketingProfitIndex !== -1 && kbaIndex !== -1 && marketingProfitIndex !== kbaIndex +1) {
      this.employeeService.sortPermissions(rearrangedResult, marketingProfitIndex, kbaIndex);
    }

    if (serviceBonusReadIndex !== -1 && serviceBonusWriteIndex !== -1 && serviceBonusWriteIndex !== serviceBonusReadIndex + 1) {
      // If not consecutive, rearrange the list
      rearrangedResult = [...rearrangedResult];
      this.employeeService.sortPermissions(rearrangedResult, serviceBonusWriteIndex, serviceBonusReadIndex + 1);
      return rearrangedResult
    } else {
      return rearrangedResult;
    }
  }

  private setLocationDropdownOptions(appPermissions: IAppPermissionMinimalDto[]) {
    this.rows = this.employeeCreateService.getTableStructure().mapApplicationsToRows(this.filterAppPermissionsList(appPermissions), this.initialPermissions);
  }

  private translateError(validationException: ValidationException): string {
    return this.exceptionTranslatorService.translate(validationException.validationErrors[0].message, validationException.validationErrors[0].members ?? []);
  }

  private filterAppPermissionsList(appPermissions: IAppPermissionMinimalDto[]): IAppPermissionMinimalDto[] {
    // remove kba (also marketing profit) if employee to be created is a supplier
    if (this.employeeCreateService.getTableStructure().getEmployeeSupplierState()) {
      return appPermissions.filter(appPermission => appPermission.permission.split('.')[0].toLowerCase() !== ApplicationName.SUPPLIER_DIRECTORY);
    }

    return appPermissions;
  }


  mapLocationToMinimalDto(location: IBaseLocationDto): IlocationMinimalDto {
    return {
      number: location.number,
      cn: location.cn,
      description: '',
      country: ''
    };
  }
}
