import {
  IAppPermissionMinimalDto
} from '../appPermission/IAppPermissionMinimalDto';
import {
  Injectable
} from '@angular/core';
import {
  IEmployeeCreateTableService
} from './iemployee-create-table.service';
import {
  Cell,
  CellType,
  MultiselectCell,
  IconTextCell,
  Icon,
  Row,
  TextCell,
  MultiSelectType,
  PmMultiSelectInputData
} from 'hagebau-coremedia';
import {
  IAppPermissionService
} from '../appPermission/iappPermission.service';
import {
  IEmployeeAppPermissionDto
} from '../appPermission/IEmployeeAppPermissionDto';
import {
  IlocationMinimalDto
} from '../location/ilocation-minimal.dto';
import {
  IAuthService
} from '../account/iauth.service';
import {
  AppSettings
} from '../appSettings/appSettings';
import {
  ILocationService
} from '../location';
import {
  IEmployeeService
} from '../employee';
import {
  Observable,
  of
} from 'rxjs';


const LOCATIONS_DROPDOWN_CELL_ID: string = 'locationsDropdownCell';
const SELECTED_LOCATIONS_CELL_ID: string = 'selectedLocationsCell';
const APP_LOCATION_SEPARATOR: string = ', ';
const DIVISION_BY_TWO: number = 2;
const MARKETING_PROFIT_READ: string = 'marketing_profit.Read';
const SUPPLIER_DIRECTORY_READ: string = 'supplier_directory.Read';


@Injectable()
export class EmployeeCreateTableService extends IEmployeeCreateTableService {
  private readonly appPermissionService: IAppPermissionService;
  private readonly authService: IAuthService;
  private readonly locationService: ILocationService;
  private readonly employeeService: IEmployeeService;

  selectedEmployeeLocations: string[] = [];
  isEmployeeSupplier: boolean = false;


  constructor(
    appPermissionService: IAppPermissionService,
    authService: IAuthService,
    locationService: ILocationService,
    employeeService: IEmployeeService
  ) {
    super();
    this.appPermissionService = appPermissionService;
    this.authService = authService;
    this.locationService = locationService;
    this.employeeService = employeeService;
  }

  public mapApplicationsToRows(applications: IAppPermissionMinimalDto[], prefillPermissions: IEmployeeAppPermissionDto[]): Row[] {
    const employeePermissions: IAppPermissionMinimalDto[] = prefillPermissions.map(prefillPermission => ({
      permission: prefillPermission.AppCn,
    }));
    const mergedApplications: IAppPermissionMinimalDto[] = applications.map(application => application);
    employeePermissions.forEach(employeePermission => {
      if (!mergedApplications.find(mergedApplication => mergedApplication.permission === employeePermission.permission)) {
        mergedApplications.push(employeePermission);
      }
    });

    return mergedApplications.map((application: IAppPermissionMinimalDto) => {
      const prefillPermission: IEmployeeAppPermissionDto | undefined = prefillPermissions.find((permission) => {
        return permission.AppCn === application.permission
      });
      return {
        rowId: application.permission,
        checked: prefillPermission !== undefined,
        cells: EmployeeCreateTableService.mapApplicationToCells(
          application.permission,
          this.appPermissionService.getLocalizedFullAppLabel(application.permission),
          this.appPermissionService.getIcon(application.permission.split('.')[0]),
          prefillPermission
        ),
        warning: false,
        inactive: !applications.some(editorApplication => editorApplication.permission === application.permission)
          || this.deactivateNonSupplierApp(application)
      }
    });
  }

  private deactivateNonSupplierApp(appPermission: IAppPermissionMinimalDto): boolean {
    const isServiceAdmin: boolean = this.authService.isServiceAdmin();
    return isServiceAdmin && (AppSettings.NON_SUPPLIER_APPS.includes(appPermission.permission.split('.')[0])
      || AppSettings.NON_SUPPLIER_APPS.includes(appPermission.permission));
  }

  public mapSelectedRowsToAppPermissions(selectedRows: Row[], locations: IlocationMinimalDto[]): IEmployeeAppPermissionDto[] {
    const uniqueLocations: IlocationMinimalDto[] = Array.from(new Set(locations));
    return selectedRows.map((row: Row) => {
      const selectedLocationsCell: Cell | undefined = row.cells.find(cell => cell.cellId && cell.cellId.includes(SELECTED_LOCATIONS_CELL_ID))
      return {
        AppCn: row.rowId,
        LocationCns: this.getPermissionLocationsFromCells(selectedLocationsCell as TextCell, uniqueLocations)
      }
    })
  }

  public mapSelectedRowToAppPermissions(
    selectedRows: Row[],
    locations: IlocationMinimalDto[],
    targetRowAppCn: string,
    selectedAppPermissions: IEmployeeAppPermissionDto[]
  ): IEmployeeAppPermissionDto[] {

    // update specific row where rowId matches targetRowId
    return selectedAppPermissions.map(row => {
      if (row.AppCn === targetRowAppCn) {
        const selectedLocationsCell: Cell | undefined = selectedRows.find(
          selectedRow => selectedRow.rowId === targetRowAppCn
        )?.cells.find(cell => cell.cellId && cell.cellId.includes(SELECTED_LOCATIONS_CELL_ID));

        const permissionLocations: string[] = this.getPermissionLocationsFromCells(selectedLocationsCell as TextCell, locations);

        return !(permissionLocations.length === 1 && permissionLocations[0] === '')
          ? {
            AppCn: row.AppCn,
            LocationCns: permissionLocations
          }
          : null;
      }
      return row;
    })
      .filter(row => row !== null) as IEmployeeAppPermissionDto[];
  }


  public mapSelectedAppLocationsToRows(
    rows: Row[],
    selectedRowIndex: number,
    previouslySelectedLocations: Map<number, string[]>
  ): Row[] {

    const selectedAppLocationsData: string[] | undefined = previouslySelectedLocations.get(selectedRowIndex);
    const selectedLocationsCellIndex: number = rows[selectedRowIndex].cells.findIndex(
      cell => cell.cellId && cell.cellId.includes(SELECTED_LOCATIONS_CELL_ID))

    if (selectedAppLocationsData !== undefined) {
      rows[selectedRowIndex].cells[selectedLocationsCellIndex] = {
        text: selectedAppLocationsData.join(APP_LOCATION_SEPARATOR),
        type: CellType.TEXT_CELL,
        cellId: SELECTED_LOCATIONS_CELL_ID
      } as TextCell;
    }
    return rows;
  }

  // Sets dropdown options of all apps, only for marketing profit set options as empty initially
  public mapLocationsToRow(
    locationHgbNumbers: string[],
    rowId: string,
    searchInputText: string,
    rows: Row[],
    showDropdownOptions: boolean
): Row[] {
    const selectedRowIndex: number = rows.findIndex(row => rowId.includes(row.rowId));
    const selectedCellIndex: number = rows[selectedRowIndex].cells.findIndex(cell => cell.cellId && cell.cellId.includes(LOCATIONS_DROPDOWN_CELL_ID));
    const selectedLocationsCellIndex: number = rows[selectedRowIndex].cells.findIndex(cell => cell.cellId && cell.cellId.includes(SELECTED_LOCATIONS_CELL_ID))
    const selectedLocations: TextCell = rows[selectedRowIndex].cells[selectedLocationsCellIndex] as TextCell;
    rows[selectedRowIndex].cells[selectedCellIndex] = {
      options: this.mapLocationsToDropdownOptions(
        locationHgbNumbers,
        selectedLocations.text.split(APP_LOCATION_SEPARATOR),
        rowId === MARKETING_PROFIT_READ
      ),
      placeholder: '',
      text: searchInputText,
      fieldName: rowId,
      type: CellType.MULTISELECT_CELL,
      multiselectType: selectedRowIndex < rows.length/DIVISION_BY_TWO ? MultiSelectType.SEARCH : MultiSelectType.SEARCH_INVERSE,
      dropfilterVisible: showDropdownOptions,
      cellId: LOCATIONS_DROPDOWN_CELL_ID,
    } as MultiselectCell
    return rows;
  }

  // Sets dropdown options of marketing profit
  public setMarketingProfitRow(
    locationHgbNumbers: string[],
    searchInputText: string,
    rows: Row[],
    showDropdownOptions: boolean
  ):Row[] {
    const rowId: string = MARKETING_PROFIT_READ;
    const selectedRowIndex: number = rows.findIndex(row => rowId.includes(row.rowId));
    const selectedCellIndex: number = rows[selectedRowIndex].cells.findIndex(cell => cell.cellId && cell.cellId.includes(LOCATIONS_DROPDOWN_CELL_ID));
    const selectedLocationsCellIndex: number = rows[selectedRowIndex].cells.findIndex(cell => cell.cellId && cell.cellId.includes(SELECTED_LOCATIONS_CELL_ID))
    const selectedLocations: TextCell = rows[selectedRowIndex].cells[selectedLocationsCellIndex] as TextCell;
    rows[selectedRowIndex].cells[selectedCellIndex] = {
      options: this.mapLocationsToDropdownOptions(
        locationHgbNumbers,
        selectedLocations.text.split(APP_LOCATION_SEPARATOR),
        false
      ),
      placeholder: '',
      text: searchInputText,
      fieldName: rowId,
      type: CellType.MULTISELECT_CELL,
      multiselectType: selectedRowIndex < rows.length/DIVISION_BY_TWO ? MultiSelectType.SEARCH : MultiSelectType.SEARCH_INVERSE,
      dropfilterVisible: showDropdownOptions,
      cellId: LOCATIONS_DROPDOWN_CELL_ID,
    } as MultiselectCell
    return rows;
  }


  // Retrieve marketing profit locations by filtering selected supplier directory row (kba) locations
  // to only keep those who have a specialization system.
  public getMarketingProfitLocations(rows: Row[], previouslySelectedLocations: Map<number, string[]>, searchInputText: string): Observable<IlocationMinimalDto[]> {
    const kbaRowIndex: number = rows.findIndex(row => row.rowId === SUPPLIER_DIRECTORY_READ);
    if (kbaRowIndex !== -1) {
      const selectedKbaLocations: string[] | undefined = previouslySelectedLocations.get(kbaRowIndex);

      if (selectedKbaLocations) {
        return this.employeeService.getLocationsWithSpecialization(selectedKbaLocations, searchInputText);
      }

    }
    return of([]);
  }

  public setEmployeeSupplierState(isSupplier: boolean) {
    this.isEmployeeSupplier = isSupplier;
  }

  public getSelectedEmployeeLocations(): string[] {
    return this.selectedEmployeeLocations;
  }

  public getEmployeeSupplierState(): boolean {
    return this.isEmployeeSupplier;
  }

  private static mapApplicationToCells(application: string, applicationName: string, applicationIcon: Icon, prefillPermission?: IEmployeeAppPermissionDto): Cell[] {
    return [
      {
        mainText: applicationName,
        icon: applicationIcon,
        type: CellType.ICON_TEXT_CELL
      } as IconTextCell,
      {
        options: [],
        placeholder: '',
        text: '',
        fieldName: application,
        type: CellType.MULTISELECT_CELL,
        multiselectType: MultiSelectType.SEARCH,
        dropfilterVisible: false,
        cellId: LOCATIONS_DROPDOWN_CELL_ID
      } as MultiselectCell,
      {
        text: prefillPermission ? prefillPermission.LocationCns.join(APP_LOCATION_SEPARATOR) : '',
        type: CellType.TEXT_CELL,
        cellId: SELECTED_LOCATIONS_CELL_ID
      } as TextCell,
    ]
  }

  private mapLocationsToDropdownOptions(locations: string[], selectedLocations: string[], emptyMarketingProfitOptions: boolean): PmMultiSelectInputData[] {

    let hgbNumbers: string[] = locations;

    // for marketing profit, we initially set options to empty and later fill them
    if (emptyMarketingProfitOptions) {
      hgbNumbers = [];
    }

    // locations are hgbNumbers here
    return hgbNumbers.map((value: string) => {
      return {
        key: value,
        value: value,
        checked: selectedLocations.includes(value)
      };
    });
  }

  private getPermissionLocationsFromCells(permissionLocations: TextCell, locations: IlocationMinimalDto[]): string[] {
    // checks if selected app locations cell is empty
    if (permissionLocations.text === '') {
      return [];
    }

    return permissionLocations.text.split(APP_LOCATION_SEPARATOR).map(
      (hgbNumber: string) => locations.find(location => location.number === hgbNumber)?.cn || '');
  }

}
