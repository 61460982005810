import {
  Injectable
} from '@angular/core';
import {
  merge,
  Observable,
  of,
  OperatorFunction
} from 'rxjs';
import {
  ILocationDto
} from './ilocation.dto';
import {
  ILocationApi
} from './ilocation-api.service';
import {
  ILocationService
} from './ilocation.service';
import {
  ILocationBulkDeleteResponseDto
} from './ilocation-bulk-delete-response.dto';
import {
  filter,
  map,
  switchMap
} from 'rxjs/operators';
import {
  ISearchTermsDto
} from '../search/isearch-terms.dto';
import {
  IModifyLocationInput
} from './imodifyLocationInput';
import {
  ILdapResponseDto
} from '../ildapResponse.dto';
import {
  ILocationRequestDto
} from './ILocationRequestDto';
import {
  IPaginationResponseDto
} from '../pagination/IPaginationResponseDto';
import {
  PaginationInfoDto
} from '../../network/api/pagination-api.service';
import {
  ILocationDetailDto
} from './ilocation-detail.dto';
import {
  IEmployeeDto
} from '../employee';
import {
  IContractDto
} from '../contract';
import {
  ContractDummy
} from '../contract/contract.service';
import {
  EmployeeDummy
} from '../employee/employee.service';
import {
  ILocationTableService
} from './ilocation-table.service';
import {
  PmInputData,
  Filter,
  FilterData,
  LocationDetailEditData
} from 'hagebau-coremedia';
import {
  ISearchService
} from '../search/isearch.service';
import {
  PmInputDataPayload
} from '../../app/location/location-details.component';
import {
  ValidationException
} from '../../network/api/http/api-exception';
import {
  IExceptionTranslatorService
} from '../exception/iexception-translator.service';
import {
  IMainLocationDto,
  IPaginatedMainLocationDto
} from './imain-location.dto';
import {
  AppSettings
} from '../appSettings/appSettings';
import * as locationConstants from './location-data';
import {
  LocationSortKeys
} from './ILocationSortDto';
import {
  IlocationMinimalDto
} from './ilocation-minimal.dto';
import {
  IBaseLocationDto
} from './ilocationBase.dto';
import {
  ValidationService
} from '../validation/validation.service';
import {
  IModifyMainLocationInput
} from './imodifyMainLocationInput';
import {
  IMainLocationHistoryDto
} from './IMainLocationHistoryDto';
import {
  IMainLocationHistoryRequestDto
} from './IMainLocationHistoryRequestDto';

const DUMMY_AMOUNT: number = 50;
const FILTER_NUMBER_INDEX: number = 0;
const FILTER_DESCRIPTION_INDEX: number = 1;
const FILTER_BRANCH_INDEX: number = 2;
const FILTER_MAIN_LOCATION_INDEX: number = 3;
const FILTER_CONTRACT_INDEX: number = 3;
export const DUMMY_DETAIL_AMOUNT: number = 5;

export class LocationDummy implements ILocationDto {
  cn: string = '0';
  number: string = '0';
  description: string = '';
  branch: string = '';
  mainLocation: string = '';
  contracts: IContractDto[] = []
  employeeCount: number = 0;
  delAdmins: IEmployeeDto[] = [];
  isIncomplete: boolean = false;
  isActive: boolean = false;
  shouldBeShownInKba: boolean = false;
  shouldBeShownInDlb: boolean = false;
  shouldBeShownInLv: boolean = false;
  allowedEmailDomains: string[] = [];
  allowedEmailAddresses: string[] = [];
}

class LocationDetailDummy implements ILocationDetailDto {
  cn: string = '0';
  branch: string = '';
  salesChannel: string = '';
  contracts: IContractDto[] = [];
  delAdmins: IEmployeeDto[] = [];
  description: string = '';
  mainLocation: ILocationDto = new LocationDummy();
  isSupplier: boolean = false;
  street: string = '';
  postCode: string = '';
  city: string = '';
  region: string = '';
  country: string = '';
  telephone: string = '';
  fax: string = '';
  email: string = '';
  shortName: string = '';
  website: string = '';
  tradeId: string = '';
  gln: string = '';
  searchTopic: string = '';
  clerkName: string = '';
  clerkEmail: string = '';
  clerkTelephone: string = '';
  clerkFax: string = '';
  clerkDepartment: string = '';
  clerkPurchaseGroup: string = '';
  clerkPurchaseName: string = '';
  supplierClassification: string = '';
  purchaseDepartment: string = '';
  purchaseGroup: string = '';
  assortment: string = '';
  centralDeletionFlag: boolean = false;
  eventITKNVVPRAT2: boolean = false;
  legallyIndependentZ000025: boolean = false;
  shouldBeShownInGv: boolean = false;
  number: string = '0';
  assignedAllianceCns: string[] = [];
  isActive: boolean = false;
  shouldBeShownInKba: boolean = false;
  shouldBeShownInDlb: boolean = false;
  shouldBeShownInLv: boolean = false;
  contactPersons: string = '';
  allowedEmailDomains: string[] = [];
  allowedEmailAddresses: string[] = []
  constructor(
    dummyContracts: IContractDto[] = [],
    dummyDelAdmins: IEmployeeDto[] = []
  ) {
    this.contracts = dummyContracts;
    this.delAdmins = dummyDelAdmins;
  }
}

@Injectable()
export class LocationService extends ILocationService {
  private readonly locationApi: ILocationApi;
  private readonly locationTableService: ILocationTableService;
  private readonly searchService: ISearchService;
  private readonly exceptionTranslatorService: IExceptionTranslatorService;

  constructor(
    locationApi: ILocationApi,
    locationTableService: ILocationTableService,
    searchService: ISearchService,
    exceptionTranslatorService: IExceptionTranslatorService
  ) {
    super();
    this.locationApi = locationApi;
    this.locationTableService = locationTableService;
    this.searchService = searchService;
    this.exceptionTranslatorService = exceptionTranslatorService;
  }

  public delete(ids: string[]): Observable<ILocationBulkDeleteResponseDto> {
    return this.checkAndThrowDefaultError(
      this.locationApi.deleteLocations(ids)
    );
  }

  public getList(requestDto: ILocationRequestDto): Observable<IPaginationResponseDto<ILocationDto>> {
    return this.checkAndThrowDefaultError(
      merge(
        LocationService.createLocationDummyData(requestDto.Pagination.PageAmount > 0 ? requestDto.Pagination.PageAmount : DUMMY_AMOUNT),
        this.loadLocations(requestDto)
      )
    );
  }

  public getSupplierList(requestDto: ILocationRequestDto): Observable<IPaginationResponseDto<ILocationDto>> {
    return this.checkAndThrowDefaultError(
      merge(
        LocationService.createLocationDummyData(requestDto.Pagination.PageAmount > 0 ? requestDto.Pagination.PageAmount : DUMMY_AMOUNT),
        this.loadSupplierLocations(requestDto)
      )
    );
  }

  public getListWithoutDummy(requestDto: ILocationRequestDto, distinctKey: keyof ILocationDto ): Observable<ILocationDto[]> {
    return this.checkAndThrowDefaultError(
      this.loadLocations(requestDto)
        .pipe(
          map(paginationResult => Array.from(paginationResult.pageResult)),
          map(locationArr => {
            //filter data to be unique values
            const keySet: Set<ILocationDto[keyof ILocationDto]> = new Set(locationArr.map(location => location[distinctKey]));
            const values: (ILocationDto|undefined)[] = Array.from(keySet).map(value => locationArr.find(obj => obj[distinctKey] === value));
            return values.filter((value): value is ILocationDto => value !== null);
          })
        )
    );
  }

  public getListWithSimplifiedLocations(requestDto: ILocationRequestDto, distinctKey: keyof IBaseLocationDto ): Observable<IBaseLocationDto[]> {
    return this.filterLocationsFromObservable(this.loadSimplifiedLocations(requestDto), distinctKey);
  }

  public getListWithSimplifiedLocationsIgnoringRoles(requestDto: ILocationRequestDto, distinctKey: keyof IBaseLocationDto ): Observable<IBaseLocationDto[]> {
    return this.filterLocationsFromObservable(this.loadSimplifiedLocationsIgnoringRoles(requestDto), distinctKey);
  }

  public getMainLocationHistory(requestDto: IMainLocationHistoryRequestDto): Observable<IPaginationResponseDto<IMainLocationHistoryDto>> {
    return this.checkAndThrowDefaultError(
      merge(
        this.loadMainLocationHistory(requestDto)
      )
    );
  }

  private filterLocationsFromObservable(observable: Observable<IPaginationResponseDto<IBaseLocationDto>>, distinctKey: keyof IBaseLocationDto): Observable<IBaseLocationDto[]> {
    return observable.pipe(
      map(paginationResult => Array.from(paginationResult.pageResult)),
      map(locationArr => {
        // Filter data to be unique values
        const keySet: Set<IBaseLocationDto[keyof IBaseLocationDto]> = new Set(locationArr.map(location => location[distinctKey]));
        const values: (IBaseLocationDto|undefined)[] = Array.from(keySet).map(value => locationArr.find(obj => obj[distinctKey] === value));
        return values.filter((value): value is IBaseLocationDto => value !== null);
      })
    );
  }

  public getMainLocationListWithoutDummy(requestDto: ILocationRequestDto): Observable<IMainLocationDto[]> {
    return this.loadMainLocations(requestDto)
      .pipe(
        map(paginationResult => Array.from(paginationResult.pageResult))
      )
  }

  public search(term: string): Observable<ISearchTermsDto> {
    return this.checkAndThrowDefaultError(
      of(term)
        .pipe(
          filter(ValidationService.isLdapConform),
          switchMap(this.locationApi.search.bind(this.locationApi))
        )
    )
  }

  public searchForAll(term: string): Observable<IlocationMinimalDto[]> {
    return this.checkAndThrowDefaultError(
      of(term)
        .pipe(
          this.preProcessSearchInput(AppSettings.MIN_SEARCH_LENGTH_DEFAULT),
          filter(ValidationService.isLdapConform),
          switchMap(this.locationApi.searchForAll.bind(this.locationApi))
        )
    )
  }

  public searchFiltered(requestDto: ILocationRequestDto): Observable<ISearchTermsDto> {
    return this.checkAndThrowDefaultError(
      this.locationApi.searchFiltered(requestDto)
    );
  }

  public update(modifyInput: IModifyLocationInput): Observable<ILdapResponseDto> {
    return this.checkAndThrowDefaultError(
      this.locationApi.updateLocation(modifyInput)
        .pipe(this.catchValidationException(this.translateError.bind(this)))
    );
  }

  public updateMainLocationEmailsAndDomains(modifyInput:IModifyMainLocationInput): Observable<ILdapResponseDto> {
    return this.checkAndThrowDefaultError(
      this.locationApi.updateMainLocationEmailsAndDomains(modifyInput)
        .pipe(this.catchValidationException(this.translateError.bind(this)))
    );
  }

  public getLocation(locationNumber: string): Observable<ILocationDetailDto> {
    return this.checkAndThrowDefaultError(
      merge(
        LocationService.createDetailDummyData(),
        this.locationApi.getLocation(locationNumber)
      )
        .pipe(this.catchValidationException(this.translateError.bind(this)))
    );
  }

  public GetChildLocationsForSecurity(mainLocationCn: string): Observable<IlocationMinimalDto[]> {
    return this.checkAndThrowDefaultError(
      this.locationApi.getChildLocationsForSecurity(mainLocationCn)
        .pipe(this.catchValidationException(this.translateError.bind(this)))
    );
  }
  public static createLocationDummyData(amount: number = DUMMY_AMOUNT): Observable<IPaginationResponseDto<ILocationDto>> {
    return of(<IPaginationResponseDto<ILocationDto>>{
      pageResult: [...Array(amount).keys()]
        .map(_ => new LocationDummy()),
      paginationInfo: new PaginationInfoDto().init({
        pageSize: amount, pageNumber: 1, totalAmount: amount, init: undefined
      })
    });
  }

  public getTableStructure(): ILocationTableService {
    return this.locationTableService;
  }
  public getSearchService(): ISearchService {
    return this.searchService;
  }

  public preProcessSearchInput(minSearchLength: number): OperatorFunction<string, string> {
    return this.searchService.preProcessSearchInput(minSearchLength);
  }

  public convertSearchResultsFromTerms(termsDto: ISearchTermsDto): PmInputData[] {
    return this.searchService.convertSearchResults(termsDto);
  }

  public convertSearchResultsFromLocationDto(searchResults: IMainLocationDto[]): PmInputDataPayload<IMainLocationDto>[] {
    return searchResults.map((location) => {
      return {
        inputData: {
          key: location.cn,
          value: location.number + ' ' + location.description,
        },
        payload: location
      }
    });
  }

  public createModifyInput(updatedLocation: ILocationDetailDto, locationDetail: ILocationDetailDto): IModifyLocationInput {
    return {
      number: locationDetail.number,
      newLocation: {
        ...updatedLocation,
        cn: locationDetail.cn
      }
    }
  }

  public isDummyData(locations: ILocationDto[]): boolean {
    return locations.filter(location => location.cn === '0').length === DUMMY_DETAIL_AMOUNT
  }

  public createNumberSearchRequestDto(filterSnippet: FilterData, filterIndex: number): ILocationRequestDto {
    return {
      Filters: {
        Cn: [],
        Number: filterSnippet.identifier.toLowerCase() === locationConstants.FILTERS[filterIndex].identifier.toLowerCase()
          ? [this.getTableStructure().toFilterDto(filterSnippet.value, false)]
          : [],
        Description: [],
        Branch: [],
        MainLocation: [],
        Contract: [],
      },
      SearchTerms: [],
      SearchFields: [],
      SortParams: {
        SortKey: 0,
        SortDirection: 0
      },
      Pagination: {
        Page: 1,
        PageAmount: AppSettings.MAX_FILTER_SUGGESTIONS
      }
    }
  }

  public updateLocationsState(ids: string[], isActive: boolean): Observable<ILdapResponseDto> {
    return this.checkAndThrowDefaultError(
      this.locationApi.updateLocationsState({
        ids: ids,
        isActive: isActive
      })
    );
  }


  public getTranslateError(validationException: ValidationException): string {
    return this.exceptionTranslatorService.translate(validationException.validationErrors[0].message, validationException.validationErrors[0].members ?? []);
  }

  public createSearchRequestDtoForFilterSuggestions(filterSnippet: FilterData): ILocationRequestDto {
    const defaultFilters: Filter[] = locationConstants.FILTERS;
    return {
      Filters: {
        Cn: [],
        Number: filterSnippet.identifier.toLowerCase() === defaultFilters[FILTER_NUMBER_INDEX].identifier.toLowerCase()
          ? [this.getTableStructure().toFilterDto(filterSnippet.value, false)]
          : [],
        Description: filterSnippet.identifier.toLowerCase() === defaultFilters[FILTER_DESCRIPTION_INDEX].identifier.toLowerCase()
          ? [this.getTableStructure().toFilterDto(filterSnippet.value, false)]
          : [],
        Branch: filterSnippet.identifier.toLowerCase() === defaultFilters[FILTER_BRANCH_INDEX].identifier.toLowerCase()
          ? [this.getTableStructure().toFilterDto(filterSnippet.value, false)]
          : [],
        MainLocation: filterSnippet.identifier.toLowerCase() === defaultFilters[FILTER_MAIN_LOCATION_INDEX].identifier.toLowerCase()
          ? [this.getTableStructure().toFilterDto(filterSnippet.value, false)]
          : [],
        Contract: filterSnippet.identifier.toLowerCase() === defaultFilters[FILTER_CONTRACT_INDEX].identifier.toLowerCase()
          ? [this.getTableStructure().toFilterDto(filterSnippet.value, false)]
          : [],
      },
      SearchTerms: [],
      SearchFields: [],
      SortParams: {
        SortKey: 0,
        SortDirection: 0
      },
      Pagination: {
        Page: 1,
        PageAmount: AppSettings.MAX_FILTER_SUGGESTIONS
      }
    }
  }

  public mapToFilterSuggestions(searchResult: ILocationDto[], valueField: keyof ILocationDto): PmInputData[] {
    return searchResult.map(location => {
      return {
        key: location.cn, // cn is required for memberOf filters
        value: location[valueField]?.toString() || ''
      }
    });
  }

  public mapMainLocationToFilterSuggestions(searchResult: IMainLocationDto[], valueField: keyof IMainLocationDto): PmInputData[] {
    return searchResult.map(location => {
      return {
        key: location.cn, // cn is required for memberOf filters
        value: location[valueField]
      }
    });
  }

  public convertSearchResults(termsDto: ISearchTermsDto): PmInputData[] {
    return termsDto.terms.map((term, index) => {
      return {
        key: index.toString(),
        value: term
      }
    })
  }

  public mapSortKey(eventSortKey: string): LocationSortKeys {
    switch (eventSortKey) {
    case 'number':
      return LocationSortKeys.Number;
    case 'description':
      return LocationSortKeys.Description;
    case 'branch':
      return LocationSortKeys.Branch;
    case 'mainLocation':
      return LocationSortKeys.MainLocation;
    default:
      return LocationSortKeys.None;
    }
  }

  public isLocationBasicDataModified(updatedLocation: LocationDetailEditData, existingLocation: ILocationDetailDto): boolean {
    return updatedLocation.basicInfo.find(field => field.fieldName === 'branch')?.value !== existingLocation.branch
      || updatedLocation.basicInfo.find(field => field.fieldName === 'description')?.value !== existingLocation.description
      || updatedLocation.basicInfo.find(field => field.fieldName === 'number')?.value !== existingLocation.number
      || updatedLocation.basicInfo.find(field => field.fieldName === 'salesChannel')?.value !== existingLocation.salesChannel
      || updatedLocation.basicInfo.find(field => field.fieldName === 'country')?.value !== existingLocation.country
      || updatedLocation.isSupplier !== existingLocation.isSupplier
  }

  public isSupplierLocation(hgbNumber: string): boolean {
    return hgbNumber.startsWith(AppSettings.SUPPLIER_LOCATION_IDENTIFIER);
  }


  private loadMainLocationHistory(requestDto: IMainLocationHistoryRequestDto): Observable<IPaginationResponseDto<IMainLocationHistoryDto>> {
    return this.checkAndThrowDefaultError(
      this.locationApi.getMainLocationHistory(requestDto)
    );
  }

  private loadLocations(requestDto: ILocationRequestDto): Observable<IPaginationResponseDto<ILocationDto>> {
    return this.checkAndThrowDefaultError(
      this.locationApi.getLocations(requestDto)
    );
  }

  private loadSupplierLocations(requestDto: ILocationRequestDto): Observable<IPaginationResponseDto<ILocationDto>> {
    return this.checkAndThrowDefaultError(
      this.locationApi.getSupplierLocations(requestDto)
    );
  }

  private loadSimplifiedLocations(requestDto: ILocationRequestDto): Observable<IPaginationResponseDto<IBaseLocationDto>> {
    return this.checkAndThrowDefaultError(
      this.locationApi.getSimplifiedLocations(requestDto)
    );
  }

  private loadSimplifiedLocationsIgnoringRoles(requestDto: ILocationRequestDto): Observable<IPaginationResponseDto<IBaseLocationDto>> {
    return this.checkAndThrowDefaultError(
      this.locationApi.getSimplifiedLocationsIgnoringRoles(requestDto)
    );
  }

  private loadMainLocations(requestDto: ILocationRequestDto): Observable<IPaginatedMainLocationDto> {
    return this.checkAndThrowDefaultError(
      this.locationApi.getMainLocations(requestDto)
    );
  }

  private static createDetailDummyData(): Observable<ILocationDetailDto> {
    return of(new LocationDetailDummy(
      [...Array(DUMMY_DETAIL_AMOUNT).keys()].map(_ => new ContractDummy()),
      [...Array(DUMMY_DETAIL_AMOUNT).keys()].map(_ => new EmployeeDummy())
    ))
  }

  private translateError(validationException: ValidationException): string {
    return this.exceptionTranslatorService.translate(validationException.validationErrors[0].message, validationException.validationErrors[0].members ?? []);
  }
}
