import {
  Injectable
} from '@angular/core';
import {
  IAppPermissionMinimalDto
} from '../appPermission/IAppPermissionMinimalDto';
import {
  Row
} from 'hagebau-coremedia';
import {
  IEmployeeAppPermissionDto
} from '../appPermission/IEmployeeAppPermissionDto';
import {
  IlocationMinimalDto
} from '../location/ilocation-minimal.dto';
import {
  Observable
} from 'rxjs';

@Injectable()
export abstract class IEmployeeCreateTableService {
    public abstract mapApplicationsToRows(applications: IAppPermissionMinimalDto[], prefillPermissions?: IEmployeeAppPermissionDto[]): Row[];

    public abstract mapSelectedRowsToAppPermissions(selectedRows: Row[], locationCns: IlocationMinimalDto[]): IEmployeeAppPermissionDto[];

    public abstract mapSelectedRowToAppPermissions(selectedRows: Row[], locations: IlocationMinimalDto[],
                                                   targetRowAppCn: string, selectedAppPermissions: IEmployeeAppPermissionDto[]): IEmployeeAppPermissionDto[];

    public abstract mapSelectedAppLocationsToRows(rows: Row[], selectedRowIndex: number, previouslySelectedLocations: Map<number, string[]>): Row[];

    public abstract mapLocationsToRow(locationHgbNumbers: string[], rowId: string, searchInputText: string, rows: Row[], showDropdownOptions: boolean): Row[];

    public abstract setMarketingProfitRow(locationHgbNumbers: string[], searchInputText: string, rows: Row[], showDropdownOptions: boolean):Row[]

    public abstract setEmployeeSupplierState(isSupplier: boolean): void;

    public abstract getSelectedEmployeeLocations(): string[];

    public abstract getEmployeeSupplierState(): boolean;

    public abstract getMarketingProfitLocations(rows: Row[], previouslySelectedLocations: Map<number, string[]>, searchInputText: string): Observable<IlocationMinimalDto[]>;
}
