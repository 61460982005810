import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  AdministrationLocationColumns,
  Cell,
  CellType,
  CheckboxCell,
  EmployeeAdministrationRights,
  Icon,
  PmSelectionCardInputData,
  Row,
  WizardHeaderStepElement,
  AlertType,
  TextCell
} from 'hagebau-coremedia';
import {
  EMPLOYEE_CREATE_STEP_IDENTIFIERS
} from '../employee-create.component';
import {
  ILocationRequestDto,
  ILocationService,
  LocationSortKeys
} from '../../../services/location';
import {
  LdapSortDirection
} from '../../../services/LdapSortDirection';
import {
  HgbLoadingKeys,
  WithLoading,
  WithModal,
  WithRx
} from '../../mixins';
import {
  ValidationException
} from '../../../network/api/http/api-exception';
import {
  IExceptionTranslatorService
} from '../../../services/exception/iexception-translator.service';
import {
  IPageFacadeService
} from '../../../services/ipage-facade';
import {
  finalize,
  map
} from 'rxjs/operators';
import {
  LOCATION_NUMBER_CELL_ID
} from '../../../services/contract/contract-table.service';
import {
  AppSettings
} from '../../../services/appSettings/appSettings';

@Component({
  selector: 'employee-create-administration-step',
  templateUrl: './employee-create-administration-step.component.html'
})
export class EmployeeCreateAdministrationStepComponent extends WithRx(WithModal(WithLoading())) implements OnChanges {
  private readonly locationService: ILocationService;
  private readonly exceptionTranslatorService: IExceptionTranslatorService;
  private readonly pageFacade: IPageFacadeService;

  stepIdentifier: number = EMPLOYEE_CREATE_STEP_IDENTIFIERS.STEP_ADMINISTRATION;
  selectedAdminRights: string = EmployeeAdministrationRights.None;
  selectionCardData: PmSelectionCardInputData[] = [
    {
      title: $localize`noAdminRightsTitle|No Admin Rights`,
      text: $localize`noAdminRightsText|Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
      value: EmployeeAdministrationRights.None,
      checked: true,
      showIcon: true,
      disabled: false,
      icon: Icon.CIRCLE_STOP
    },
    {
      title: $localize`setAsDelAdminTitle|Set as delegated Admin`,
      text: $localize`setAsDelAdminText|Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
      value: EmployeeAdministrationRights.DelAdmin,
      checked: false,
      showIcon: true,
      disabled: false,
      icon: Icon.STAR
    }]

  // table
  showTable: boolean = false;
  delAdminLocationCns: string[] = [];
  columnNames: AdministrationLocationColumns = {
    checkBoxColumn: 'Checkbox',
    numberColumn: $localize`number|Number`,
    locationNameColumn: $localize`location name|Location name`,
    mainLocationNameColumn: $localize `mainLocation|Main Location`,
    permissionColumn: $localize`permission|Permission`,
  };
  delAdminLocationRows: Row[] = [];
  readonly HgbLoadingKeys: typeof HgbLoadingKeys = HgbLoadingKeys;

  constructor(
    locationService: ILocationService,
    exceptionTranslatorService: IExceptionTranslatorService,
    pageFacade: IPageFacadeService
  ) {
    super();
    this.locationService = locationService;
    this.exceptionTranslatorService = exceptionTranslatorService;
    this.pageFacade = pageFacade;
  }

  @Input()
  wizardHeaderSteps: WizardHeaderStepElement[] = [];

  @Input()
  currentCreationStep: number = -1;

  @Input()
  assignedLocationCns: string[] = [];

  @Input()
  assignedRows: Row[] = [];

  @Output()
  onBackClicked: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  onNextClicked: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  onAdministrationUpdated: EventEmitter<string[]> = new EventEmitter<string[]>();

  ngOnChanges(changes: SimpleChanges) {
    if (!(changes['currentCreationStep'] && this.currentCreationStep === this.stepIdentifier) ){
      return;
    }

    if (this.delAdminLocationCns.length > 0){ //init view with checked boxes
      this.selectionCardData.forEach(card => {
        card.checked = card.value === EmployeeAdministrationRights.DelAdmin
      })
      this.onCardSelectionChange(EmployeeAdministrationRights.DelAdmin);
      return;
    }
    const locationNumbers: string[] = this.assignedRows.flatMap(row =>
      row.cells
        .filter(cell => cell.cellId === LOCATION_NUMBER_CELL_ID)
        .map(cell => (cell as TextCell).text));
    const deladminCard: PmSelectionCardInputData | undefined = this.selectionCardData.find(card => card.value === EmployeeAdministrationRights.DelAdmin);
    if (deladminCard) {
      deladminCard.disabled = locationNumbers.length === 0 || locationNumbers.every(number => number.startsWith(AppSettings.SUPPLIER_LOCATION_IDENTIFIER));
    }
  }

  setInitialDeladminLocations(locationCns: string[]) {
    this.delAdminLocationCns = locationCns;
  }

  onNextButtonClicked() {
    this.onNextClicked.emit();
    this.setAndEmitDelAdminLocations();
  }

  setAndEmitDelAdminLocations() {
    this.onAdministrationUpdated.emit(this.delAdminLocationCns);
  }

  onBackButtonClicked() {
    this.onBackClicked.emit(this.stepIdentifier);
  }

  onCardSelectionChange(adminRights: string) {
    this.selectedAdminRights = adminRights;
    this.showTable = adminRights === EmployeeAdministrationRights.DelAdmin;
    adminRights === EmployeeAdministrationRights.DelAdmin ?
      this.fetchDelAdminLocations(this.assignedLocationCns) :
      this.delAdminLocationCns = [];
  }

  onLocationCheckboxChange(locationCns: string[]) {
    this.delAdminLocationCns = locationCns;
  }

  private initDelAdminLocationRows(): string[]  {
    return this.delAdminLocationRows
      .filter((row: Row) => row.checked)
      .map((row: Row) => row.rowId);
  }

  private checkAllDeladminLocations() {
    this.delAdminLocationRows.forEach((row: Row) => {
      if(this.delAdminLocationCns.length === 0 || this.delAdminLocationCns.includes(row.rowId)) {
        const rowCheckbox: CheckboxCell = row.cells.find((cell: Cell) => cell.type === CellType.CHECKBOX_CELL) as CheckboxCell;
        rowCheckbox.value = true;
        row.checked = true;
      }
    });
    this.delAdminLocationRows = [...this.delAdminLocationRows];
  }

  fetchDelAdminLocations(assignedLocationCns: string[]) {
    if (assignedLocationCns.length === 0 && this.delAdminLocationCns.length === 0) {
      this.delAdminLocationRows = [];
      this.onCardSelectionChange(EmployeeAdministrationRights.None);
      return;
    }
    this.showLoading(HgbLoadingKeys.LOAD_ASSIGNED_LOCATIONS_FOR_DELADMINS);

    // still include previously set delAdminLocationCns, unless specifically unset
    const delAdminApplicableLocationCns: string[] = Array.from(new Set([...assignedLocationCns,...this.delAdminLocationCns]));

    this.locationService.getListWithoutDummy(this.createRequestDto(delAdminApplicableLocationCns), 'cn')
      .pipe(
        map(locations => locations.filter(location => !location.number.startsWith(AppSettings.SUPPLIER_LOCATION_IDENTIFIER))), // filter out supplier locations, they cannot be assigned to DelAdmins
        this.catchValidationException(this.translateError.bind(this)),
        map(delAdminLocation => this.locationService.getTableStructure().mapLocationsToMinimalRows(Array.from(delAdminLocation))),
        finalize(() => {
          this.hideLoading(HgbLoadingKeys.LOAD_ASSIGNED_LOCATIONS_FOR_DELADMINS);
          this.checkAllDeladminLocations();
          this.delAdminLocationCns = this.initDelAdminLocationRows();
        })
      )
      .subscribe(delAdminLocationRows => {
        this.delAdminLocationRows = delAdminLocationRows;
      }, error => {
        this.pageFacade.emitAlert({
          label: error.message ? error.message : $localize`Data Loading Error|Couldn't load data.`,
          alertType: AlertType.ERROR
        });
      });
  }

  createRequestDto(selectedLocationCns: string[]): ILocationRequestDto {
    return {
      Filters: {
        Cn: selectedLocationCns.map(selectedLocationCn => ({
          Value: selectedLocationCn,
          IsNotFilter: false
        })),
        Branch: [],
        MainLocation: [],
        Description: [],
        Number: [],
        Contract: []
      },
      SearchTerms: [],
      SortParams: {
        SortKey: LocationSortKeys.None,
        SortDirection: LdapSortDirection.Ascending
      },
      Pagination: {
        Page: 1,
        PageAmount: 500
      },
      SearchFields: []
    };
  }

  private translateError(validationException: ValidationException): string {
    return this.exceptionTranslatorService.translate(validationException.validationErrors[0].message, validationException.validationErrors[0].members ?? []);
  }
}
