import {
  ChipData,
  ColumnNamesContract,
  ColumnNamesDelAdmins,
  ExitType,
  HeadingTexts,
  LocationDetailEditData,
  PmCheckboxFieldData,
  PmCheckboxState,
  PmInputData,
  PmInputFieldData,
  PmInputType,
  PmSearchInputFieldData,
  Row,
  IMultiselectFieldData,
  PmInputFieldSizeType,
  AlertType,
  PmCheckboxComponent
} from 'hagebau-coremedia';
import {
  Component,
  HostListener,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  finalize,
  map,
  tap
} from 'rxjs/operators';
import {
  Observable,
  OperatorFunction,
  Subject,
  Subscription
} from 'rxjs';
import {
  Path
} from '../app-routing.module';
import {
  HgbLoadingKeys,
  WithLoading,
  WithModal,
  WithRx
} from '../mixins';
import {
  ILocationDetailDto,
  ILocationService,
  IMainLocationDto
} from '../../services/location';
import {
  ILdapResponseDto
} from '../../services/ildapResponse.dto';
import {
  ComponentCanDeactivate
} from '../../services/navigation';
import {
  AppSettings
} from '../../services/appSettings/appSettings';
import {
  IBaseContractDto,
  IContractDto,
  IContractService
} from '../../services/contract';
import {
  LocationEditViewData,
  LocationTableService
} from '../../services/location/location-table.service';
import {
  IPaginationResponseDto
} from '../../services/pagination/IPaginationResponseDto';
import {
  ContractService
} from '../../services/contract/contract.service';
import {
  IPageFacadeService
} from '../../services/ipage-facade';
import {
  ActivatedRoute
} from '@angular/router';
import {
  ContractTableService
} from '../../services/contract/contract-table.service';
import {
  TableSettings
} from '../../services/tableSettings/tableSettings';
import {
  ITableSwitchService
} from '../../services/table-switch/itable-switch.service';
import {
  PaginationSearchData,
  TableSwitchService,
  TableSwitchTableData
} from 'src/services/table-switch/table-switch.service';
import {
  IAllianceService
} from '../../services/alliance/ialliance.service';
import {
  IAllianceDto
} from '../../services/alliance/ialliance.dto';
import * as locationConstants from  '../../services/location/location-data';
import {
  IAuthService
} from '../../services/account/iauth.service';

export interface PmInputDataPayload<T> {
  inputData: PmInputData,
  payload: T
}

const URL_MODUS_PART: number = 2;

@Component({
  selector: 'location-details',
  templateUrl: './location-details.component.html',
})
/*eslint max-lines: ["error", {"max": 650, "skipBlankLines": true}]*/
export class LocationDetailsComponent extends WithRx(WithModal(WithLoading())) implements OnInit, OnDestroy, ComponentCanDeactivate {
  //services
  private readonly locationService: ILocationService;
  private readonly allianceService: IAllianceService;
  private readonly contractService: IContractService;
  private readonly pageFacade: IPageFacadeService;
  private readonly activatedRoute: ActivatedRoute;
  private readonly tableSwitchService: ITableSwitchService;
  private readonly authService: IAuthService;

  //rxjs
  private confirmUpdateClickSubject$ = new Subject<LocationDetailEditData>();
  private mainLocationSearchPreviewSubject$ = new Subject<[PmInputFieldData, string]>();
  private allContractsSearchPreviewSubject$ = new Subject<string>();
  private countriesSearchPreviewSubject$ = new Subject<[PmInputFieldData, string]>();
  private assignedContractsSearchPreviewSubject$ = new Subject<string>();

  //subscriptions
  updateSubscription: Subscription = new Subscription();
  mainLocationSearchPreviewSubscription: Subscription = new Subscription();
  allContractsSearchPreviewSubscription: Subscription = new Subscription();
  countriesSearchPreviewSubscription: Subscription = new Subscription();
  assignedContractsSearchPreviewSubscription: Subscription = new Subscription();

  //table
  assignedContractsTableTextView: HeadingTexts = TableSettings.ASSIGNED_CONTRACTS_TABLE_NAMES;
  allContractsTableText: HeadingTexts = TableSettings.ALL_CONTRACTS_TABLE_NAMES;
  assignedContractsTableTextEdit: HeadingTexts = TableSettings.ASSIGNED_CONTRACTS_TABLE_EDIT_NAMES;
  desktopEditNotice: string = $localize`desktop-contract-table-edit-info`;

  //tableSwitchData
  allContracts: TableSwitchTableData = TableSwitchService.getDefaultTableData();
  assignedContracts: TableSwitchTableData = TableSwitchService.getDefaultTableData();

  rowsDelAdmins: Row[] = [];
  columnNamesDelAdmins: ColumnNamesDelAdmins = TableSettings.DEFAULT_DEL_ADMIN_COLUMN_NAMES;
  columnNamesContract: ColumnNamesContract = TableSettings.COLUMN_NAMES_CONTRACT_ASSIGNMENT

  showCheckboxOnDesktopOnly: boolean = true;
  resetTableHeaderCheckboxWithPagination: boolean = true;

  //state
  editMode: boolean = false;
  exitMode: ExitType = ExitType.SAVE;
  location: ILocationDetailDto = locationConstants.DEFAULT_LOCATION_DETAIL_DATA;
  locationFields: PmInputFieldData[] = [];
  locationFieldsDisplayOnly: PmInputFieldData[] = [];
  locationFieldsSingleLine: IMultiselectFieldData[] = [];
  activeSearchField: string = '';
  contractsSelectable: IContractDto[] = [];
  contractsAssigned: IBaseContractDto[] = [];
  error: boolean = false;
  mainLocationSuggestions: IMainLocationDto[] = [];
  updatedLocation: LocationDetailEditData = locationConstants.LOCATION_DETAIL_EDIT_DATA_DEFAULT
  isNavPromptDisabled: boolean = false;
  routeBackToTable: boolean = false;
  isDelAdminOnly: boolean = true;
  isServiceAdmin: boolean = false;

  alliances: IAllianceDto[] = [];
  activeBox: PmCheckboxFieldData = {
    ...locationConstants.ACTIVE_BOX, disabled: this.editMode
  };
  supplierBox: PmCheckboxFieldData = {
    ...locationConstants.SUPPLIER_BOX
  };
  legallyIndependentZ000025Box: PmCheckboxFieldData = {
    ...locationConstants.LEGALLY_INDEPENDENT_BOX_Z000025
  };
  centralDeletionFlagBox: PmCheckboxFieldData = {
    ...locationConstants.CENTRAL_DELETION_FLAG_BOX
  };
  eventITKNVVPRAT2Box: PmCheckboxFieldData = {
    ...locationConstants.EVENT_IT_BOX
  };
  shouldBeShownInKbaBox: PmCheckboxFieldData = locationConstants.SHOULD_BE_SHOWN_IN_KBA_BOX;
  shouldBeShownInDlbBox: PmCheckboxFieldData = locationConstants.SHOULD_BE_SHOWN_IN_DLB_BOX;
  shouldBeShownInLvBox: PmCheckboxFieldData = locationConstants.SHOULD_BE_SHOWN_IN_LV_BOX;
  shouldBeShownInGvBox: PmCheckboxFieldData = locationConstants.SHOULD_BE_SHOWN_IN_GV_BOX;
  /**
   * @ignore
   */
  readonly HgbLoadingKeys: typeof HgbLoadingKeys = HgbLoadingKeys;

  /*eslint max-params: ["warn", 6]*/
  constructor(
    locationService: ILocationService,
    allianceService: IAllianceService,
    contractService: IContractService,
    pageFacade: IPageFacadeService,
    activatedRoute: ActivatedRoute,
    tableSwitchService: ITableSwitchService,
    authService: IAuthService
  ) {
    super();
    this.locationService = locationService;
    this.allianceService = allianceService;
    this.pageFacade = pageFacade;
    this.contractService = contractService;
    this.activatedRoute = activatedRoute;
    this.tableSwitchService = tableSwitchService;
    this.authService = authService;
  }

  ngOnInit(): void {
    this.activateConfirmUpdateClickObserver();
    this.activateMainLocationSearchPreviewSubject();
    this.activateAllContractsSearchPreviewSubject();
    this.activateAssignedContractsSearchPreviewSubject();
    this.activateCountrySearchPreviewSubject();

    this.allContracts.paginationData = this.tableSwitchService.mapPaginationData(locationConstants.PAGINATION, locationConstants.PAGE_SIZES);
    this.assignedContracts.paginationData = this.tableSwitchService.mapPaginationData(locationConstants.PAGINATION, locationConstants.PAGE_SIZES);
    this.isDelAdminOnly = this.authService.isDelAdminOnly()
    this.isServiceAdmin = this.authService.isServiceAdmin();
    let locationNumber: string = '';

    this.activatedRoute.url.subscribe(
      url => {
        this.editMode = url[url.length - URL_MODUS_PART].path === 'edit';
        locationNumber = url[url.length - 1].path
      }
    )
    this.initializeLocation(locationNumber);

    if (this.editMode)
      this.initContractTable();
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.updateSubscription.unsubscribe();
    this.mainLocationSearchPreviewSubscription.unsubscribe();
    this.allContractsSearchPreviewSubscription.unsubscribe();
    this.countriesSearchPreviewSubscription.unsubscribe();
    this.assignedContractsSearchPreviewSubscription.unsubscribe();
  }

  // @HostListener body listens for nav inside angular app
  @HostListener('body:beforeunload')
  canDeactivate(): boolean {
    // prevent second prompt after save or cancel prompt confirmation
    if (this.isNavPromptDisabled) return true;

    const editedLocation: LocationDetailEditData = {
      basicInfo: this.locationFields,
      viewOnlyInfo: this.locationFields,
      singleLineInfo: this.locationFieldsSingleLine,
      contracts: this.assignedContracts.rows,
      isSupplier: this.supplierBox.state === PmCheckboxState.ACTIVE,
      legallyIndependentZ000025: this.legallyIndependentZ000025Box.state === PmCheckboxState.ACTIVE,
      eventITKNVVPRAT2: this.eventITKNVVPRAT2Box.state === PmCheckboxState.ACTIVE,
      centralDeletionFlag: this.centralDeletionFlagBox.state === PmCheckboxState.ACTIVE,
      exitMode: ExitType.CANCEL,
      routeBackToTableBtn: false,
      isActive: this.activeBox.state === PmCheckboxState.ACTIVE,
      shouldBeShownInKba: this.shouldBeShownInKbaBox.state === PmCheckboxState.ACTIVE,
      shouldBeShownInDlb: this.shouldBeShownInDlbBox.state === PmCheckboxState.ACTIVE,
      shouldBeShownInLv: this.shouldBeShownInLvBox.state === PmCheckboxState.ACTIVE,
      shouldBeShownInGv: this.shouldBeShownInGvBox.state === PmCheckboxState.ACTIVE
    }
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away
    return !this.isLocationModified(editedLocation);
  }

  /** -------------------- Edit Mode Handler ------------------- **/

  editModeHandler(editMode: boolean) {
    const path: Path = editMode ? Path.LOCATION_DETAILS_EDIT : Path.LOCATION_DETAILS_VIEW;
    this.pageFacade.navigateWithParams(path, new Map<string, string>([['id', this.location.number]]));
  }

  /** -------------------- Modal Handlers ------------------- **/

  openConfirmSaveModal(updatedLocation: LocationDetailEditData) {
    const modalText: string = this.assignedContracts.searchTerms.length > 0
      ? $localize`WarnSaveAssignedContractsActiveSearch|Are you sure you want to save the changes? There is an active search for the assigned contracts and not all assigned contracts may be displayed.`
      : $localize`Are you sure you want to save the changes you made?`;
    this.setModalTexts($localize`Really save changes|Really save changes`, modalText);
    this.setButtonLabels($localize`Cancel|Cancel`, $localize`Save|Save`);

    this.showModal();
    this.updatedLocation = updatedLocation;
    this.exitMode = updatedLocation.exitMode;
  }

  openConfirmCancelModal(updatedLocation: LocationDetailEditData) {
    this.setModalTexts(
      $localize`Really cancel changes|Really cancel changes`,
      $localize`Are you sure you want to discard the changes you made?`
    );
    this.setButtonLabels($localize`Cancel|Cancel`, $localize`Discard|Discard`);

    this.showModal();
    this.exitMode = updatedLocation.exitMode;
    this.routeBackToTable = updatedLocation.routeBackToTableBtn;
  }

  onConfirm() {
    this.isNavPromptDisabled = true;
    if (this.exitMode === ExitType.SAVE) {
      this.confirmUpdateClickSubject$.next(this.updatedLocation);
      return
    }
    this.hideModal();
    if (this.routeBackToTable) {
      this.routeToTable();
      return;
    }
    this.editModeHandler(false);
  }

  /** -------------------- Table Switch Handlers ------------------- **/

  addContractsHandler(rows: Row[]) {
    this.assignedContracts.rows = this.assignedContracts.rows.concat(rows);

    this.contractsAssigned = this.contractsAssigned.concat(
      this.contractsSelectable.filter(
        contract => rows.map(row => row.rowId).includes(contract.cn)
      )
    );

    this.getAllContracts(this.allContracts.paginationData.currentPage, this.allContracts.paginationData.currentPageSize);
    this.getAssignedContracts(this.assignedContracts.paginationData.currentPage, this.assignedContracts.paginationData.currentPageSize);
  }

  removeContractsHandler(rows: Row[]) {
    this.assignedContracts.rows = this.assignedContracts.rows.filter((rowAssigned: Row) =>
      !rows.some((rowRemove: Row) => rowAssigned.rowId === rowRemove.rowId)
    );

    this.contractsAssigned = this.contractsAssigned.filter(
      contract => !rows.map(row => row.rowId).includes(contract.cn)
    );

    this.getAllContracts(this.allContracts.paginationData.currentPage, this.allContracts.paginationData.currentPageSize);
    this.getAssignedContracts(this.assignedContracts.paginationData.currentPage, this.assignedContracts.paginationData.currentPageSize);
  }

  /** -------------------- Search Handlers ------------------- **/

  mainLocationSearchInputChangeHandler(searchInputData: [PmInputFieldData, string]) {
    this.mainLocationSearchPreviewSubject$.next(searchInputData);
  }

  allContractsSearchInputChangeHandler(searchInput: string) {
    this.allContractsSearchPreviewSubject$.next(searchInput);
  }

  countriesSearchInputChangeHandler(searchInputData: [PmInputFieldData, string]) {
    this.countriesSearchPreviewSubject$.next(searchInputData);
  }

  assignedContractsSearchInputChangeHandler(searchInput: string) {
    this.assignedContractsSearchPreviewSubject$.next(searchInput);
  }

  contractSearchChipsChangedHandler(chips: ChipData[]) {
    this.allContracts.paginationData.currentPage = 0;
    this.allContracts.searchTerms = chips;
    this.updateContractsResult(true);
  }

  assignedContractSearchChipsChangedHandler(chips: ChipData[]) {
    this.assignedContracts.searchTerms = chips;
    this.updateAssignedContractsResult();
  }

  /** -------------------- Pagination Handlers ------------------- **/

  allContractShowPage(pageNumber: number) {
    this.allContracts.paginationData.currentPage = pageNumber;
    this.updateContractsResult(true);
  }

  assignedContractShowPage(pageNumber: number) {
    this.assignedContracts.paginationData.currentPage = pageNumber;
    this.updateAssignedContractsResult();
  }

  allContractPageAmountChanged(pageAmount: PmInputData) {
    this.allContracts.paginationData.currentPageSize = parseInt(pageAmount.value);
    this.updateContractsResult();
  }

  assignedContractPageAmountChanged(pageAmount: PmInputData) {
    this.assignedContracts.paginationData.currentPageSize = parseInt(pageAmount.value);
    this.updateAssignedContractsResult();
  }

  private updateContractsResult(loadContractsOverviewOnly: boolean = false) {
    const updateData: PaginationSearchData = this.tableSwitchService.getUpdateTableData(this.allContracts.searchTerms, this.allContracts.paginationData);

    this.allContracts.searchTerms = updateData.selectedSearchTerms;
    this.getAllContracts(updateData.page, updateData.pageSize, loadContractsOverviewOnly);
  }

  private updateAssignedContractsResult() {
    const updateData: PaginationSearchData = this.tableSwitchService.getUpdateTableData(this.assignedContracts.searchTerms, this.assignedContracts.paginationData);
    this.assignedContracts.searchTerms = updateData.selectedSearchTerms;
    this.getAssignedContracts(updateData.page, updateData.pageSize);
  }

  private initContractTable() {
    ContractService.createDummyData(AppSettings.INITIAL_PAGE_SIZE)
      .subscribe(paginatedContractResponse => {
        const contractData: [Row[], IPaginationResponseDto<IContractDto>] =
          [this.locationService.getTableStructure().mapLocationContractsToRows(Array.from(paginatedContractResponse.pageResult)), paginatedContractResponse];
        this.setAllContractResult(contractData);
      });
  }

  private initializeLocation(locationNumber: string) {
    this.showLoading(HgbLoadingKeys.LOAD_DETAILS);
    this.createCancelableSwitchMap(this.getLocation.bind(this, locationNumber))
      .pipe(
        finalize(() => {
          this.hideLoading(HgbLoadingKeys.LOAD_DETAILS);
          this.allContracts.paginationData.currentPageSize = this.allContracts.paginationData.currentPageSize !== 0 ?
            this.allContracts.paginationData.currentPageSize : parseInt(this.allContracts.paginationData.pageSizes[0].value);
          this.assignedContracts.paginationData.currentPageSize = this.assignedContracts.paginationData.currentPageSize !== 0 ?
            this.assignedContracts.paginationData.currentPageSize : parseInt(this.assignedContracts.paginationData.pageSizes[0].value);
        })
      )
      .subscribe((locationData: LocationEditViewData) => {
        this.initializeLocationFields(locationData);
        this.initializeCheckboxStates(locationData);
        const hgbNumber: string | undefined = locationData.basicInfo.find(basicInfo => basicInfo.fieldName === 'number')?.value;
        this.supplierBox.state = hgbNumber && this.locationService.isSupplierLocation(hgbNumber) ? PmCheckboxState.ACTIVE : PmCheckboxState.DEFAULT;
        this.rowsDelAdmins = locationData.delAdmins;
        this.contractsAssigned = locationData.contracts;
        this.assignedContracts.rows = this.locationService.getTableStructure().mapLocationContractsToRows(locationData.contracts);
        this.setAllianceOptions();
        if (this.editMode && !this.contractService.isDummyData(locationData.contracts)) {// dont fetch on dummy data
          this.getAllContracts(this.allContracts.paginationData.currentPage, this.allContracts.paginationData.currentPageSize, true);
          this.getAssignedContracts(this.assignedContracts.paginationData.currentPage, this.assignedContracts.paginationData.currentPageSize);
        }
      }, _ => {
        this.error = true;
        this.pageFacade.emitAlert({
          label: $localize`Data Loading Error|Couldn't load data.`, alertType: AlertType.ERROR
        });
      }
      );
  }

  private setAllianceOptions(): void {
    this.allianceService.getList()
      .subscribe(result => {
        const alliancesField: IMultiselectFieldData = this.locationFieldsSingleLine.find((field: IMultiselectFieldData) => field.fieldName === 'alliances')
          ?? {
            selections: [], options: [], size: PmInputFieldSizeType.DEFAULT, active: false, disabled: true, label: '', fieldName: '', text: '', placeholder: ''
          };
        alliancesField.options = result.map((alliance: IAllianceDto) => ({
          key: alliance.cn, value: alliance.cn, checked: alliancesField.selections.some(selection => selection.key === alliance.cn)
        }));
      })
  }

  private getLocation(locationNumber: string): Observable<LocationEditViewData> {
    return this.locationService.getLocation(locationNumber)
      .pipe(
        tap(result => this.location = result),
        tap(this.pageFacade.setLocationDetailsBreadcrumbs.bind(this.pageFacade)),
        map(this.locationService.getTableStructure().mapLocationToEditViewData.bind(this.locationService.getTableStructure())),
      )
  }

  private getAllContracts(currentPage: number, currentPageSize: number, loadContractsOverviewOnly: boolean = false) {
    const loadables: HgbLoadingKeys[] = loadContractsOverviewOnly
      ? [HgbLoadingKeys.LOAD_CONTRACTS_OVERVIEW]
      : [HgbLoadingKeys.LOAD_CONTRACTS_OVERVIEW, HgbLoadingKeys.LOAD_ASSIGNED_CONTRACTS];

    this.createCancelableSwitchMap(_ => this.tableSwitchService.loadContracts(this.allContracts.searchTerms, currentPage, currentPageSize, this.contractsAssigned.map(contract => contract.cn), true))
      .pipe(
        this.useLoadingAnimation(...loadables),
        tap(this.resetContractSearch.bind(this))
      ).subscribe((contractData: [Row[], IPaginationResponseDto<IContractDto>]) => {
        this.setAllContractResult(contractData);
      }, fetchError => {
        this.allContracts.rows = [];
        this.contractsSelectable = [];
        this.pageFacade.emitAlert({
          label: fetchError.message ? fetchError.message : $localize`Data Loading Error|Couldn't load data.`,
          alertType: AlertType.ERROR
        });
      }
      );
  }

  private getAssignedContracts(currentPage: number, currentPageSize: number) {
    const includeCns: string[] = this.contractsAssigned.map(contract => contract.cn);
    if (includeCns.length === 0) { //edge case for no applicable filters, no result is possible
      this.setAssignedContractResult([[], {
        pageResult: [],
        paginationInfo: {
          pageSize: this.assignedContracts.paginationData.currentPageSize, pageNumber: 1, totalAmount: 0
        }
      }]);
      return;
    }
    this.createCancelableSwitchMap(_ => this.tableSwitchService.loadContracts(this.assignedContracts.searchTerms, currentPage, currentPageSize, includeCns, false))
      .pipe(
        this.useLoadingAnimation(...[HgbLoadingKeys.LOAD_ASSIGNED_CONTRACTS]),
        tap(this.resetAssignedContractSearch.bind(this))
      ).subscribe((contractData: [Row[], IPaginationResponseDto<IContractDto>]) => {
        this.setAssignedContractResult(contractData);
      }, fetchError => {
        this.assignedContracts.rows = [];
        this.pageFacade.emitAlert({
          label: fetchError.message ? fetchError.message : $localize`Data Loading Error|Couldn't load data.`,
          alertType: AlertType.ERROR
        });
      }
      );
  }

  public exitHandler(modifiedLocation: LocationDetailEditData) {
    modifiedLocation.viewOnlyInfo = this.locationFieldsDisplayOnly;
    if (this.isLocationModified(modifiedLocation)) {
      modifiedLocation.exitMode === ExitType.CANCEL ? this.openConfirmCancelModal(modifiedLocation) : this.openConfirmSaveModal(modifiedLocation);
    } else if (modifiedLocation.exitMode === ExitType.SAVE) {
      this.confirmUpdateClickSubject$.next(modifiedLocation);
    } else {
      if (modifiedLocation.routeBackToTableBtn) {
        this.routeToTable();
      } else {
        this.editModeHandler(false);
      }
    }
  }

  private isLocationModified(updatedLocation: LocationDetailEditData): boolean {
    return this.locationService.isLocationBasicDataModified(updatedLocation, this.location)
      || this.isContractsModified() || this.isMainLocationModified(updatedLocation)
  }

  private isContractsModified() {
    return this.contractsAssigned.length !== this.location.contracts.length
      || this.contractsAssigned.some(contract => !this.location.contracts.map(initialLocationContract => initialLocationContract.cn).includes(contract.cn));
  }

  private isMainLocationModified(updatedLocation: LocationDetailEditData) {
    if (this.location.mainLocation?.number === undefined && this.location.mainLocation?.description === undefined && updatedLocation.basicInfo.find(field => field.fieldName === 'mainLocation')?.value === null) {
      return false
    }
    return updatedLocation.basicInfo.find(field => field.fieldName === 'mainLocation')
      ?.value !== this.locationService.getTableStructure().formatMainLocation(this.location.mainLocation?.number, this.location.mainLocation?.description)
  }

  private activateMainLocationSearchPreviewSubject() {
    this.mainLocationSearchPreviewSubscription = this.mainLocationSearchPreviewSubject$
      .pipe(
        tap(inputFieldChangeData => this.activeSearchField = inputFieldChangeData[0].fieldName),
        map(inputFieldChangeData => inputFieldChangeData[1]),
        this.locationService.preProcessSearchInput(AppSettings.MIN_SEARCH_LENGTH_DEFAULT),
        map(searchTerm => LocationTableService.createSearchRequestDto([searchTerm], AppSettings.LOCATION_SEARCH_FIELDS)),
        this.switchMapCancelable(this.locationService.getMainLocationListWithoutDummy.bind(this.locationService)),
        map(this.locationService.convertSearchResultsFromLocationDto),
      )
      .subscribe((inputData: PmInputDataPayload<IMainLocationDto>[]) => {
        const field: PmSearchInputFieldData = this.locationFields.find(_field => _field.fieldName === this.activeSearchField && _field.type === PmInputType.SEARCH) as PmSearchInputFieldData;

        if (field !== undefined) {
          field.inputData = inputData.map(payload => payload.inputData);
          field.searchSuggestionsVisible = inputData.length > 0;
          this.mainLocationSuggestions = inputData.map(payload => payload.payload);
        }
      })
  }

  private activateAllContractsSearchPreviewSubject() {
    this.allContractsSearchPreviewSubscription = this.allContractsSearchPreviewSubject$
      .pipe(this.processTableSearchPreview(true))
      .subscribe(suggestions => {
        this.allContracts.inputData = suggestions;
        this.allContracts.searchSuggestionsVisible = suggestions.length > 0;
      });
  }


  private activateCountrySearchPreviewSubject() {
    this.countriesSearchPreviewSubscription = this.countriesSearchPreviewSubject$
      .pipe(
        tap(inputFieldChangeData => this.activeSearchField = inputFieldChangeData[0].fieldName),
        map(inputFieldChangeData => inputFieldChangeData[1]),
        this.locationService.preProcessSearchInput(AppSettings.MIN_SEARCH_LENGTH_ZERO),
        map(searchTerm => LocationTableService.filterCountryOptions(searchTerm)),
      )
      .subscribe(suggestions => {
        const field: PmSearchInputFieldData = this.locationFields.find(_field => _field.fieldName === this.activeSearchField && _field.type === PmInputType.SEARCH) as PmSearchInputFieldData;

        if (field !== undefined) {
          field.inputData = suggestions;
          field.searchSuggestionsVisible = suggestions.length > 0;
        }
      });
  }

  private activateAssignedContractsSearchPreviewSubject() {
    this.assignedContractsSearchPreviewSubscription = this.assignedContractsSearchPreviewSubject$
      .pipe(this.processTableSearchPreview(false))
      .subscribe(suggestions => {
        this.assignedContracts.inputData = suggestions;
        this.assignedContracts.searchSuggestionsVisible = suggestions.length > 0;
      })
  }

  private processTableSearchPreview(excludeLocationCn: boolean): OperatorFunction<string, PmInputData[]> {
    return (source: Observable<string>): Observable<PmInputData[]> =>
      source.pipe(
        this.contractService.preProcessSearchInput(),
        map(searchTerm => ContractTableService.mapContractMinimalRequestDto([searchTerm], AppSettings.CONTRACT_SEARCH_FIELDS, this.tableSwitchService.createCnFilter(this.contractsAssigned.map(contract => contract.cn), excludeLocationCn))),
        this.switchMapCancelable(this.contractService.searchFiltered.bind(this.contractService)),
        map(this.contractService.convertSearchResults.bind(this.contractService))
      );
  }

  private activateConfirmUpdateClickObserver(): void {
    this.updateSubscription = this.confirmUpdateClickSubject$
      .pipe(
        this.hideModalOperator(),
        this.useLoadingAnimation(HgbLoadingKeys.LOAD_DETAILS, HgbLoadingKeys.LOAD_CONTRACTS_OVERVIEW),
        this.cancelOnDestroy(),
      )
      .pipe(
        map(location => this.locationService.getTableStructure().mapEditDataToLocation(location, this.contractsAssigned, this.mainLocationSuggestions)),
        tap(location => location.mainLocation = location.mainLocation || this.location.mainLocation),
        map(location => this.locationService.createModifyInput(location, this.location)),
        this.switchMapCancelable(this.locationService.update.bind(this.locationService)),
      )
      .subscribe((_: ILdapResponseDto) => {
        const number: string = this.locationFields.find(field => field.fieldName === 'number')?.value || this.location.number;
        this.pageFacade.navigateWithParams(Path.LOCATION_DETAILS_VIEW, new Map<string, string>([['id', number]]));
        this.pageFacade.emitAlert({
          label: $localize`Data Update Success|Update successful.`, alertType: AlertType.SUCCESS
        })
      }, (error: Error) => {
        this.pageFacade.emitAlert({
          label: error.message, alertType: AlertType.ERROR
        })
      }
      );
  }

  private setAllContractResult(contractData: [Row[], IPaginationResponseDto<IContractDto>]): void {
    this.allContracts.rows = contractData[0];
    this.contractsSelectable = Array.from(contractData[1].pageResult);
    this.locationService.getTableStructure().getPaginationService().applyPaginationAttributes(this.allContracts.paginationData.pagination, contractData[1].paginationInfo);
    this.allContracts.paginationData.currentPageSize = contractData[1].paginationInfo.pageSize;
  }

  private setAssignedContractResult(contractData: [Row[], IPaginationResponseDto<IContractDto>]): void {
    this.assignedContracts.rows = contractData[0];
    this.locationService.getTableStructure().getPaginationService().applyPaginationAttributes(this.assignedContracts.paginationData.pagination, contractData[1].paginationInfo);
    this.assignedContracts.paginationData.currentPageSize = contractData[1].paginationInfo.pageSize;
  }

  private resetContractSearch(): void {
    this.allContracts.inputData = [];
    this.allContracts.searchSuggestionsVisible = false;
  }

  private resetAssignedContractSearch(): void {
    this.assignedContracts.inputData = [];
    this.assignedContracts.searchSuggestionsVisible = false;
  }

  private routeToTable() {
    this.pageFacade.navigateWithParams(Path.LOCATION_OVERVIEW, new Map<string, string>([['', '']]))
  }

  private initializeLocationFields(locationData: LocationEditViewData) {
    this.locationFields = locationData.basicInfo;
    this.locationFieldsDisplayOnly = locationData.displayOnlyInfo;
    this.locationFieldsSingleLine = locationData.singleLineInfo;
  }

  private initializeCheckboxStates(locationData: LocationEditViewData) {
    this.activeBox.state = PmCheckboxComponent.mapToActiveCheckboxState(locationData.isActive);
    this.shouldBeShownInKbaBox.state = PmCheckboxComponent.mapToActiveCheckboxState(locationData.shouldBeShownInKba);
    this.shouldBeShownInDlbBox.state = PmCheckboxComponent.mapToActiveCheckboxState(locationData.shouldBeShownInDlb);
    this.shouldBeShownInLvBox.state = PmCheckboxComponent.mapToActiveCheckboxState(locationData.shouldBeShownInLv);
    this.shouldBeShownInGvBox.state = PmCheckboxComponent.mapToActiveCheckboxState(locationData.shouldBeShownInGv);
    this.legallyIndependentZ000025Box.state = PmCheckboxComponent.mapToActiveCheckboxState(locationData.legallyIndependentZ000025);
    this.centralDeletionFlagBox.state = PmCheckboxComponent.mapToActiveCheckboxState(locationData.centralDeletionFlag);
    this.eventITKNVVPRAT2Box.state = PmCheckboxComponent.mapToActiveCheckboxState(locationData.eventITKNVVPRAT2);
  }
}
