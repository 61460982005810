import {
  BaseContractDtoSchema
} from '../contract';
import {
  BaseEmployeeDtoSchema
} from '../employee';
import {
  z
} from 'zod';
import {
  MainLocationDtoSchema
} from './imain-location.dto';

export const LocationDetailDtoSchema = z.object({ // eslint-disable-line @typescript-eslint/typedef
  cn: z.string(),
  number: z.string(),
  description: z.string(),
  branch: z.string(),
  salesChannel: z.string(),
  mainLocation: z.nullable(MainLocationDtoSchema),
  isSupplier: z.boolean(),
  street: z.string(),
  postCode: z.string(),
  city: z.string(),
  region: z.string(),
  country: z.string(),
  telephone: z.string(),
  fax: z.string(),
  email: z.string(),
  shortName: z.string(),
  website: z.string(),
  tradeId: z.string(),
  gln: z.string(),
  searchTopic: z.string(),
  clerkName: z.string(),
  clerkEmail: z.string(),
  clerkTelephone: z.string(),
  clerkFax: z.string(),
  clerkDepartment: z.string(),
  clerkPurchaseGroup: z.string(),
  clerkPurchaseName: z.string(),
  supplierClassification: z.string(),
  purchaseDepartment: z.string(),
  purchaseGroup: z.nullable(z.string()),
  assortment: z.string(),
  legallyIndependentZ000025: z.boolean(),
  centralDeletionFlag: z.boolean(),
  eventITKNVVPRAT2: z.boolean(),
  shouldBeShownInGv: z.boolean(),
  delAdmins: z.array(BaseEmployeeDtoSchema),
  contracts: z.array(BaseContractDtoSchema),
  assignedAllianceCns: z.array(z.string()),
  isActive: z.boolean(),
  shouldBeShownInKba: z.boolean(),
  shouldBeShownInDlb: z.boolean(),
  shouldBeShownInLv: z.boolean(),
  contactPersons: z.string(),
  allowedEmailDomains: z.array(z.string()),
  allowedEmailAddresses: z.array(z.string())

})

export type ILocationDetailDto = z.infer<typeof LocationDetailDtoSchema>;
