export enum ApplicationName {
  DEBTOR = 'debtor',
  CREDITOR = 'creditor',
  SERVICE_BONUS = 'service_bonus',
  SERVICE_BONUS_WRITE = 'service_bonus.Write',
  PAYMENT_ADVICE = 'payment_advice',
  SUPPLIER_DIRECTORY = 'supplier_directory',
  MARKETING_PROFIT = 'marketing_profit',
  VALUATION_OVERVIEW = 'valuation_overview',
}
