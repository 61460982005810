import {
  Observable
} from 'rxjs';
import {
  ILocationBulkDeleteResponseDto
} from './ilocation-bulk-delete-response.dto';
import {
  IPaginatedLocationDto,
  IPaginatedMinimalLocationDto
} from './ilocation.dto';
import {
  IModifyLocationInput
} from './imodifyLocationInput';
import {
  ILdapResponseDto
} from '../ildapResponse.dto';
import {
  ILocationRequestDto
} from './ILocationRequestDto';
import {
  ILocationDetailDto
} from './ilocation-detail.dto';
import {
  ILocationCreateBulkRequestDto
} from './ilocation-import.dto';
import {
  ISearchTermsDto
} from '../search/isearch-terms.dto';
import {
  ILocationBulkCreateResponseDto
} from './ilocation-bulk-create-response.dto';
import {
  IPaginatedMainLocationDto
} from './imain-location.dto';
import {
  ILocationStateUpdateDto
} from './ILocationStateUpdateDto';
import {
  IlocationMinimalDto
} from './ilocation-minimal.dto';
import {
  IModifyMainLocationInput
} from './imodifyMainLocationInput';
import {
  IPaginatedMainLocationHistoryDto
} from './IMainLocationHistoryDto';
import {
  IMainLocationHistoryRequestDto
} from './IMainLocationHistoryRequestDto';
import {
  IRetentionRateUpdateDto
} from './IRetentionRateUpdateDto';
import {
  IRetentionRateBulkUpdateResponseDto
} from './iretention-rate-bulk-update-response.dto';

export abstract class ILocationApi {
  abstract createLocations(requestDto: ILocationCreateBulkRequestDto): Observable<ILocationBulkCreateResponseDto>;

  abstract createFullLocations(requestDto: ILocationCreateBulkRequestDto): Observable<ILocationBulkCreateResponseDto>;

  abstract getLocations(requestDto: ILocationRequestDto): Observable<IPaginatedLocationDto>;

  abstract getSupplierLocations(requestDto: ILocationRequestDto): Observable<IPaginatedLocationDto>;

  abstract getMainLocations(requestDto: ILocationRequestDto): Observable<IPaginatedMainLocationDto>;

  abstract getChildLocationsForSecurity(mainLocationCn: string): Observable<IlocationMinimalDto[]>;

  abstract deleteLocations(ids: string[]): Observable<ILocationBulkDeleteResponseDto>;

  abstract search(term: string): Observable<ISearchTermsDto>;

  abstract searchForAll(term: string): Observable<IlocationMinimalDto[]>

  abstract searchFiltered(requestDto: ILocationRequestDto): Observable<ISearchTermsDto>;

  abstract updateLocation(modifyInput: IModifyLocationInput): Observable<ILdapResponseDto>;

  abstract updateMainLocationEmailsAndDomains(modifyInput: IModifyMainLocationInput): Observable<ILdapResponseDto>;

  abstract getLocation(locationNumber: string): Observable<ILocationDetailDto>;

  abstract updateLocationsState(stateUpdate: ILocationStateUpdateDto): Observable<ILdapResponseDto>;

  abstract getSimplifiedLocations(requestDto: ILocationRequestDto): Observable<IPaginatedMinimalLocationDto>;

  abstract getSimplifiedLocationsIgnoringRoles(requestDto: ILocationRequestDto): Observable<IPaginatedMinimalLocationDto>;

  abstract getMainLocationHistory(requestDto: IMainLocationHistoryRequestDto): Observable<IPaginatedMainLocationHistoryDto>;

  abstract updateRetentionRates(stateUpdate: IRetentionRateUpdateDto): Observable<IRetentionRateBulkUpdateResponseDto>;
}
