import {
  z
} from 'zod';

export enum ImportStatus
{
  Success,
  SuccessUpdate,
  InProgress,
  LdapError,
  CreateLocationError,
  UpdateLocationError,
  UpdateMainLocationError,
  CreateRoleError,
  CreateAppLocationError,
  NoDescription,
  NoMainLocation,
  NoHgbNumber,
  UnknownError,
  CreateMainLocationError
}

export const CreateLocationResponseDtoSchema = z.object({ // eslint-disable-line @typescript-eslint/typedef
  cn: z.string(),
  number: z.string(),
  description: z.string(),
  branch: z.string(),
  isSupplier: z.boolean(),
  mainLocationCn: z.string(),
  shouldBeShownInKba: z.boolean(),
  shouldBeShownInDlb: z.boolean(),
  shouldBeShownInLv: z.boolean(),
  shouldBeShownInGv: z.boolean(),
  response: z.nativeEnum(ImportStatus)
})

export const AddRetentionRateResponseDtoSchema = z.object({ // eslint-disable-line @typescript-eslint/typedef
  retentionRate: z.string(),
  response: z.nativeEnum(ImportStatus)
})

export type ICreateLocationResponseDto = z.infer<typeof CreateLocationResponseDtoSchema>;
export type IAddRetentionRateResponseDto = z.infer<typeof AddRetentionRateResponseDtoSchema>;
