import {
  ActionCell,
  Cell,
  CellType,
  CheckboxCell,
  FilterData,
  Icon,
  IImageData,
  ImageSize,
  ImageTextCell,
  ImageType,
  LabelType,
  MoreButtonElement,
  MoreButtonElementType,
  PmInputFieldData,
  PmInputType,
  PmMenuActionData,
  Row,
  StatusCell,
  TextCell
} from 'hagebau-coremedia';
import {Injectable} from '@angular/core';
import {
  EmployeeState,
  IEmployeeDto,
  IEmployeeFilterDto,
  IEmployeeRequestDto,
  IEmployeeSortDto,
  IEmployeeTableService
} from './index';
import {
  IBaseLocationDto,
  ILocationDto
} from '../location';
import {EmployeeManagementLevel} from './employee-management-level.enum';
import {IFilterDto} from '../IFilterDto';
import {IEmployeeDetailDto} from './iemployee-detail.dto';
import {ICurrentPageInfo} from '../pagination/IPaginationInfoDto';
import {ISearchInfo} from '../search/search.service';
import {IAppPermissionDto} from '../appPermission/IAppPermissionDto';
import {IAppPermissionService} from '../appPermission/iappPermission.service';
import {
  menuActions,
  EMPLOYEE_FILTER_IDENTIFIERS
} from './data';
import {EmployeeTitle} from './employee-title.enum';
import {IAuthService} from '../account/iauth.service';
import {ROLES} from '../account/auth/auth.constants';
import {CurrentEmployeeWithRoles} from '../account/auth/auth.service';
import {AppSettings} from '../appSettings/appSettings';

const MAX_LOCATION_STR_LENGTH: number = 18;

export interface EmployeeEditViewData {
  displayOnlyInfo: PmInputFieldData[],
  hasTermsAndConditionsChecked: boolean,
  isDelegatedAdmin: boolean,
  photo: IImageData,
  status: LabelType,
  locations: ILocationDto[],
  mainLocations: ILocationDto[],
  managementLevel: EmployeeManagementLevel
  appPermissions: IAppPermissionDto[]
  delAdminLocations: ILocationDto[];
  uid: string
}

export const localizedValueMapping: Map<string, string> = new Map<string, string>([
  [$localize`inactive|Inactive`, 'inactive'],
  [$localize`active|Active`, 'active'],
  [$localize`locked|Locked`, 'locked'],
  [$localize`General Management|General Management`, 'generalManagement'],
  [$localize`Middle Management|Middle Management`, 'middleManagement'],
  [$localize`Top Management|Top Management`, 'topManagement'],
  [$localize`false|False`, 'false'],
  [$localize`true|True`, 'true'],
  [$localize`debtorAppFull|Debtor`, 'debtor.read'],
  [$localize`creditorAppFull|Creditor`, 'creditor.read'],
  [$localize`serviceBonusAppDebtor|Service bonus debtor`, 'service_bonus.read'],
  [$localize`serviceBonusAppCreditor|Service bonus creditor`, 'service_bonus.write'],
  [$localize`paymentAdviceAppFull|Payment Advice`, 'payment_advice.read'],
  [$localize`supplierDirectoryRead|Supplier Directory App`, 'supplier_directory.read'],
  [$localize`marketingProfit|Marketing-profit`, 'marketing_profit.read'],
  [$localize`valuationOverviewAppFUll|Valuation Overview`, 'valuation_overview.read'],
  [$localize`Miss|Miss`, 'Miss'],
  [$localize`Mister|Mister`, 'Mister'],
  [$localize`Diverse|Diverse`, 'Diverse'],
  [$localize`Not specified|Not specified`, 'Not_specified']
]);

export type EmployeeWithRoles = {
  employee: IEmployeeDto;
  roles: string[];
}

@Injectable()
export class EmployeeTableService extends IEmployeeTableService {

  private readonly appPermissionService: IAppPermissionService;
  private readonly authService: IAuthService;

  constructor(appPermissionService: IAppPermissionService, authService: IAuthService) {
    super();
    this.appPermissionService = appPermissionService;
    this.authService = authService;
    this.mapAppPermissionToRow = this.mapAppPermissionToRow.bind(this);
  }

  public static mapEmployeeRequestDto(
    filters: FilterData[],
    searchInfo: ISearchInfo,
    sortInfo: IEmployeeSortDto,
    pageInfo: ICurrentPageInfo
  ): IEmployeeRequestDto {
    return {
      Filters: EmployeeTableService.mapActiveFilters(filters),
      SearchTerms: searchInfo.searchTerms,
      SearchFields: searchInfo.searchFields,
      SortParams: {
        SortKey: sortInfo.SortKey,
        SortDirection: sortInfo.SortDirection
      },
      Pagination: {
        Page: pageInfo.currentPage,
        PageAmount: pageInfo.currentPageSize
      }
    }
  }

  public mapEmployeesToRows(employees: IEmployeeDto[], currentEmployee: CurrentEmployeeWithRoles): Row[] {
    return employees.map((employee: IEmployeeDto) => {
      return {
        rowId: employee.uid,
        checked: false,
        cells: EmployeeTableService.mapEmployeeToRow(employee, currentEmployee),
        warning: false,
        inactive: employee.status !== EmployeeState.Active
      }
    })
  }

  public getMenuActions(): PmMenuActionData[] {
    return menuActions;
  }

  public static toFilterDto(filterValue: string, isNotFilter: boolean = false): IFilterDto {
    // map filter values to strings that the backend understands

    const mappedFilterValue: string = localizedValueMapping.get(filterValue) || '';

    return {
      Value: mappedFilterValue || filterValue,
      IsNotFilter: isNotFilter
    };
  }

  // just a configuration method
  // eslint-disable-next-line max-lines-per-function
  private static mapEmployeeToRow(employee: IEmployeeDto, currentEmployee: CurrentEmployeeWithRoles): Cell[] {
    return [
      {
        value: false,
        type: CellType.CHECKBOX_CELL
      } as CheckboxCell,
      {
        text: (employee.surName ? employee.surName + ', ' : '') + employee.givenName,
        image: {
          size: ImageSize.XXXS,
          type: ImageType.ROUND,
          imageSources: [{
            path: '/assets/default_employee.jpg',
            width: '900w'
          }],
          base64ImageData: employee.photo,
          imageSizeRules: ['100vw']
        },
        type: CellType.IMAGE_TEXT_CELL
      } as ImageTextCell,
      {
        text: employee.email,
        type: CellType.TEXT_CELL
      } as TextCell,
      {
        status: EmployeeTableService.mapStateToLabel(employee.status),
        type: CellType.STATUS_CELL
      } as StatusCell,
      {
        text: EmployeeTableService.getLocationsString(employee.mainLocations),
        type: CellType.TEXT_CELL
      } as TextCell,
      {
        text: EmployeeTableService.getLocationsString(employee.locations),
        type: CellType.TEXT_CELL
      } as TextCell,
      {
        text: EmployeeTableService.mapManagementLevelToLocalizedLabel(employee.managementLevel),
        type: CellType.TEXT_CELL
      } as TextCell,
      {
        actions: this.getRowActions(employee, currentEmployee),
        type: CellType.ACTION_CELL
      } as ActionCell
    ];
  }

  // just a configuration method
  // eslint-disable-next-line max-lines-per-function
  public mapEmployeeToEditViewData(employee: IEmployeeDetailDto): EmployeeEditViewData {
    return <EmployeeEditViewData>{
      photo: {
        size: ImageSize.M,
        type: ImageType.ROUND,
        imageSources: [{
          path: '/assets/default_employee.jpg',
          width: '250w'
        }],
        base64ImageData: employee.photo,
        imageSizeRules: ['100vw']
      },
      status: EmployeeTableService.mapStateToLabel(employee.status),
      managementLevel: EmployeeTableService.mapManagementLevelToLocalizedLabel(employee.managementLevel),
      isDelegatedAdmin: employee.isDelAdmin,
      hasTermsAndConditionsChecked: employee.isCheckedTermsAndConditions,
      displayOnlyInfo: [
        {
          label: $localize`title|title`,
          fieldName: 'title',
          value: EmployeeTableService.mapTitleToLocalizedLabel(employee.title),
          type: PmInputType.DEFAULT,
          required: false,
          editable: false
        },
        {
          label: $localize`firstName|firstName`,
          fieldName: 'firstName',
          value: employee.firstName,
          type: PmInputType.DEFAULT,
          required: false,
          editable: false
        },
        {
          label: $localize`lastName|lastName`,
          fieldName: 'lastName',
          value: employee.surname,
          type: PmInputType.DEFAULT,
          required: false,
          editable: false
        },
        {
          label: $localize`userNumber|userNumber`,
          fieldName: 'userNumber',
          value: employee.hgbUserNumber,
          type: PmInputType.DEFAULT,
          required: false,
          editable: false
        },
        {
          label: $localize`email|Email`,
          fieldName: 'email',
          value: employee.email,
          type: PmInputType.DEFAULT,
          required: false,
          editable: false
        },
        {
          label: $localize`isSupplier|IsSupplier`,
          fieldName: 'isSupplier',
          value: EmployeeTableService.mapIsSupplierToLocalizedLabel(employee.isSupplier),
          type: PmInputType.DEFAULT,
          required: false,
          editable: false
        },
        {
          label: $localize`phoneNumber|PhoneNumber`,
          fieldName: 'phoneNumber',
          value: employee.phoneNumber,
          type: PmInputType.DEFAULT,
          required: false,
          editable: false
        },
        {
          label: $localize`mobileNumber|MobileNumber`,
          fieldName: 'mobileNumber',
          value: employee.mobileNumber,
          type: PmInputType.DEFAULT,
          required: false,
          editable: false
        },
        {
          label: $localize`branch|Branch`,
          fieldName: 'sector',
          value: employee.sector,
          type: PmInputType.DEFAULT,
          required: false,
          editable: false
        },
        {
          label: $localize`mainLocations|mainLocations`,
          fieldName: 'mainLocations',
          value: employee.mainLocations.map(mainLocation => mainLocation.number),
          type: PmInputType.DEFAULT,
          required: false,
          editable: false
        },
        {
          label: $localize`departmentFunction|departmentFunction`,
          fieldName: 'departmentFunction',
          value: employee.departmentFunction,
          type: PmInputType.DEFAULT,
          required: false,
          editable: false
        }
      ],
      locations: employee.locations,
      delAdminLocations: employee.delAdminLocations,
      appPermissions: employee.appPermissions
    };
  }

  private static mapStateToLabel(state: EmployeeState): LabelType {
    switch (state) {
    case EmployeeState.Active:
      return LabelType.ACTIVE;
    case EmployeeState.Inactive:
      return LabelType.INACTIVE;
    case EmployeeState.Locked:
      return LabelType.LOCKED;
    }
  }

  private static mapManagementLevelToLocalizedLabel(managementLevel: EmployeeManagementLevel): string {
    switch (managementLevel) {
    case EmployeeManagementLevel.GeneralManagement:
      return $localize`General Management|General Management`;
    case EmployeeManagementLevel.MiddleManagement:
      return $localize`Middle Management|Middle Management`;
    case EmployeeManagementLevel.TopManagement:
      return $localize`Top Management|Top Management`;
    }
  }

  private static mapTitleToLocalizedLabel(title: EmployeeTitle): string {
    switch (title) {
    case EmployeeTitle.Miss:
      return $localize`Miss|Miss`;
    case EmployeeTitle.Mister:
      return $localize`Mister|Mister`;
    case EmployeeTitle.Diverse:
      return $localize`Diverse|Diverse`;
    case EmployeeTitle.NotSpecified:
      return $localize`Not specified|Not specified`;
    }
  }

  private static mapIsSupplierToLocalizedLabel(isSupplier: boolean): string {
    return isSupplier ? ($localize`true|True`) : ($localize`false|False`);
  }

  // just a configuration method
  // eslint-disable-next-line max-lines-per-function,complexity
  private static getRowActions(employee: IEmployeeDto, currentEmployee: CurrentEmployeeWithRoles): MoreButtonElement[] {
    const isServiceAdmin: boolean = currentEmployee.roles.includes(ROLES.SERVICE_ADMIN)
    //checks if employee has only supplier locations
    const isTargetEmployeeSupplier: boolean =
      employee.locations.length > 0 && employee.locations.every(location =>
        AppSettings.isSupplierLocation(location.number));
    return [
      {
        moreBtnId: '1',
        icon: Icon.TRASH,
        text: $localize`Delete|Delete`,
        link: '',
        type: MoreButtonElementType.DELETE,
        disabled: employee.number === currentEmployee.employee.number || isServiceAdmin && !isTargetEmployeeSupplier
      },
      {
        moreBtnId: '2',
        icon: employee.status === EmployeeState.Active ? Icon.CIRCLE_CLOSE : Icon.CIRCLE_CHECK,
        text: employee.status === EmployeeState.Active ? $localize`Deactivate|Deactivate` : $localize`Activate|Activate`,
        link: '',
        type: employee.status === EmployeeState.Active ? MoreButtonElementType.DEACTIVATE : MoreButtonElementType.ACTIVATE,
        disabled: employee.number === currentEmployee.employee.number || (!isTargetEmployeeSupplier && isServiceAdmin)
      },
      {
        moreBtnId: '3',
        icon: Icon.CIRCLE_INFO,
        text: $localize`View|View`,
        link: '',
        type: MoreButtonElementType.VIEW,
        disabled: false
      },
      {
        moreBtnId: '4',
        icon: Icon.PEN,
        text: $localize`Edit|Edit`,
        link: '',
        type: MoreButtonElementType.EDIT,
        disabled: employee.number === currentEmployee.employee.number || (!isTargetEmployeeSupplier && isServiceAdmin)
      },
      {
        moreBtnId: '5',
        icon: Icon.LOCK,
        text: $localize`password reset|Reset password`,
        link: '',
        type: MoreButtonElementType.RESET_PASSWORD,
        disabled: employee.status === EmployeeState.Inactive
      }
    ];
  }

  private static getLocationsString(mainLocations: IBaseLocationDto[]) : string {
    const locationNumbers: string[] = mainLocations.map(mainLocation => mainLocation.number);
    const locationStr: string = locationNumbers.join(', ');

    if (locationStr.length >= MAX_LOCATION_STR_LENGTH) {
      const locationStrShortened: string = locationStr
        .substring(0, MAX_LOCATION_STR_LENGTH - 1)
        .replace(/,\s*$/, ''); // remove comma at end if there is one

      return locationStrShortened + ' ...';
    }

    return locationStr + ' ';
  }

  private static mapActiveFilters(filters: FilterData[]): IEmployeeFilterDto {
    return {
      Mail: filters.filter(filter => filter.identifier.toLowerCase() === EMPLOYEE_FILTER_IDENTIFIERS.EMAIL).map(filter => EmployeeTableService.toFilterDto(filter.value, filter.isNotFilter)),
      Status: filters.filter(filter => filter.identifier.toLowerCase() === EMPLOYEE_FILTER_IDENTIFIERS.STATUS).map(filter => EmployeeTableService.toFilterDto(filter.value, filter.isNotFilter)),
      MainLocation: filters.filter(filter => filter.identifier.toLowerCase() === EMPLOYEE_FILTER_IDENTIFIERS.MAIN_LOCATION).map(filter => EmployeeTableService.toFilterDto(filter.value, filter.isNotFilter)),
      Locations: [filters.filter(filter => filter.identifier.toLowerCase() === EMPLOYEE_FILTER_IDENTIFIERS.LOCATIONS).map(filter => EmployeeTableService.toFilterDto(filter.value, filter.isNotFilter))],
      Admin: filters.filter(filter => filter.identifier.toLowerCase() === EMPLOYEE_FILTER_IDENTIFIERS.ADMIN).map(filter => EmployeeTableService.toFilterDto(filter.value, filter.isNotFilter)),
      ManagementLevel: filters.filter(filter => filter.identifier.toLowerCase() === EMPLOYEE_FILTER_IDENTIFIERS.MANAGEMENT_LEVEL).map(filter => EmployeeTableService.toFilterDto(filter.value, filter.isNotFilter)),
      AppPermission: filters.filter(filter => filter.identifier.toLowerCase() === EMPLOYEE_FILTER_IDENTIFIERS.APP_PERMISSION).map(filter => (EmployeeTableService.toFilterDto(filter.value, filter.isNotFilter))),
    }
  }

  public mapLocationToRow(location: ILocationDto): Cell[] {
    return [
      {
        text: location.number,
        type: CellType.TEXT_CELL
      } as TextCell,
      {
        text: location.description,
        type: CellType.TEXT_CELL
      } as TextCell,
      {
        text: location.contracts?.map(contract => contract.name).join(', '),
        type: CellType.TEXT_CELL
      } as TextCell
    ];
  }

  public mapAppPermissionToRow(appPermission: IAppPermissionDto): Row {
    return {
      cells: [
            {
              value: false,
              type: CellType.CHECKBOX_CELL
            } as CheckboxCell,
            {
              text: this.appPermissionService.getLocalizedAppLabel(appPermission.appIds),
              type: CellType.TEXT_CELL
            } as TextCell,
            {
              text: appPermission.locationIds?.map(appLocation => appLocation).join(', '),
              type: CellType.TEXT_CELL
            } as TextCell,
            {
              text: this.appPermissionService.getLocalizedPermissionLabel(appPermission.permission),
              type: CellType.TEXT_CELL
            } as TextCell
      ],
      checked: false,
      rowId: String(appPermission.appIds),
      warning: false,
      inactive: false,
    }
  }

  public mapEmployeeLocationsToRows(locations: ILocationDto[]): Row[] {
    return locations.map((location: ILocationDto) => {
      return {
        rowId: String(location.cn),
        checked: false,
        cells: this.mapLocationToRow(location),
        warning: false,
        inactive: false
      };
    });
  }

  public mapEmployeeAppPermissionToRows(appPermissions: IAppPermissionDto[]): Row[] {
    const sortedPermissions: IAppPermissionDto[] = this.sortPermissionsArray(appPermissions);
    const groupedAppPermissions: Record<string, IAppPermissionDto> = this.groupBy(
      sortedPermissions,
      (appPermission: IAppPermissionDto) => appPermission.appIds,
      (firstPermission: IAppPermissionDto, secondPermission: IAppPermissionDto) => ({
        ...firstPermission,
        'permission': `${this.appPermissionService.getLocalizedPermissionLabel(firstPermission.permission)},
      ${this.appPermissionService.getLocalizedPermissionLabel(secondPermission.permission)}`
      })
    );
    return (Object.values(groupedAppPermissions).map(this.mapAppPermissionToRow));
  }

  public groupBy = <ITEM, KEY extends string>(
    arr: ITEM[],
    Key: (i: ITEM) => KEY,
    transformer: (item1: ITEM, item2: ITEM) => ITEM
  ): Record<KEY, ITEM> =>
    arr.reduce((groups: Record<KEY, ITEM>, item: ITEM) => {
      if (typeof groups[Key(item)] === 'undefined') {
        groups[Key(item)] = item;
      } else {
        groups[Key(item)] = transformer(groups[Key(item)], item);
      }
      return groups;
    }, {

    } as Record<KEY, ITEM>);



  public mapDetailLocationToRow(location: ILocationDto): Cell[] {
    return [
      {
        text: location.number,
        type: CellType.TEXT_CELL
      } as TextCell,
      {
        text: location.description,
        type: CellType.TEXT_CELL
      } as TextCell,
      {
        text: location.contracts?.map(contract => contract.name).join(', '),
        type: CellType.TEXT_CELL
      } as TextCell
    ];
  }

  public mapEmployeeDetailLocationsToRows(locations: ILocationDto[]): Row[] {
    return locations.map((location: ILocationDto) => {
      return {
        rowId: String(location.cn),
        checked: false,
        cells: this.mapDetailLocationToRow(location),
        warning: false,
        inactive: false
      };
    });
  }

  private sortPermissionsArray(appPermissions: IAppPermissionDto[]): IAppPermissionDto[]  {
    const marketingProfitIndex: number = appPermissions.findIndex(permission => permission.appIds === 'marketing_profit');
    const supplierDirectoryIndex: number = appPermissions.findIndex(permission => permission.appIds === 'supplier_directory');
    if (marketingProfitIndex !== -1 && supplierDirectoryIndex !== -1 && marketingProfitIndex !== supplierDirectoryIndex + 1) {
      const [marketingProfitItem] = appPermissions.splice(marketingProfitIndex, 1);
      appPermissions.splice(supplierDirectoryIndex + 1, 0, marketingProfitItem);
    }
    return appPermissions;
  }
}
