import {
  Injectable
} from '@angular/core';
import {
  ILocationImportService
} from './ilocation-import.service';
import {
  ILocationApi
} from './ilocation-api.service';
import {
  from,
  fromEvent,
  Observable,
} from 'rxjs';
import {
  IFullLocationImportDto,
  ILocationImportDto,
  ILocationRowOfFileDto,
  IRetentionRateImportDto,
  IRetentionrateRowOfFileDto
} from './ilocation-import.dto';
import {
  ICreateLocationResponseDto,
  ImportStatus
} from './ilocation-import-response.dto';
import {
  switchMap,
  take
} from 'rxjs/operators';
import {
  ImportData
} from 'hagebau-coremedia';
import {
  IEmployeeApi
} from '../employee';
import {
  ILocationBulkCreateResponseDto
} from './ilocation-bulk-create-response.dto';
import {
  AppSettings
} from '../appSettings/appSettings';
import {
  DELIMITER,
  TRUTHY_CSV_VALUES
} from '../shared/import-data';
import {
  ILocationService
} from './ilocation.service';


export type ConvertLineToLocationCallback = (line: string, colNames: string[]) => ILocationImportDto;

export type ConvertLineToRetentionRateCallback = (line: string, colNames: string[]) => IRetentionRateImportDto;

export enum CSV_IDENTIFIERS {
  HGB_NUMBER = 'Standortnummer',
  DESCRIPTION = 'Beschreibung',
  HGB_SECTOR = 'Branche',
  MAIN_LOCATION_CN = 'Hauptstandortnummer',
  SUPPLIER = 'Lieferant',
  SHOULD_BE_SHOWN_IN_KBA = 'Kennz. Anzeige KBA',
  SHOULD_BE_SHOWN_IN_DLB = 'Kennz. Anzeige Dienstleistungsbonus',
  SHOULD_BE_SHOWN_IN_LV = 'Kennz. Anzeige im Liefantenverzeichnis',
  SHOULD_BE_SHOWN_IN_GV = 'Gesellschafterliste',
  RESULT = 'Ergebnis',
}

export enum CSV_EXTENDED_IDENTIFIERS {
  STREET = 'Strasse',
  POST_CODE = 'PLZ',
  CITY = 'Stadt',
  REGION = 'Region',
  COUNTRY = 'Land',
  TELEPHONE = 'Telefon',
  FAX = 'Fax',
  EMAIL = 'Email',
  SHORT_NAME = 'Kurz-Bezeichnung',
  WEBSITE = 'Website',
  TRADE_ID = 'Handels ID',
  GLN = 'GLN',
  SEARCH_TOPIC = 'Such Thema',
  CLERK_FIRSTNAME = 'Verwaltung Vorname',
  CLERK_SURNAME = 'Verwaltung Nachname',
  CLERK_EMAIL = 'Verwaltung Email',
  CLERK_TELEPHONE = 'Verwaltung Telefon',
  CLERK_FAX = 'Verwaltung Fax',
  CLERK_DEPARTMENT = 'Verwaltung Abteilung',
  CLERK_PURCHASE_GROUP = 'Verwaltung Einkausgemeinschaft',
  SUPPLIER_CLASSIFICATION = 'Lieferant Klassifizierung',
  PURCHASE_DEPARTMENT = 'Einkaufsabteilung',
  PURCHASE_GROUP = 'Einkaufsgemeinschaft',
  ASSORTMENT = 'Sortiment',
  CENTRAL_DELETION_FLAG = 'Zentrale Loeschvormerkung',
  Event_IT_KNVV_PRAT2 = 'Event IT KNVV- PRAT2',
  LEGALLY_INDEPENDENT_Z000025 = 'Rechtlich selbstaendig Z000025',
  DELIVERY_LOCK = 'Einkaufssperre',
  BOOKING_LOCK = 'Buchungssperre',
  ALLOWED_DOMAINS = 'erlaubte Domains',
  ALLOWED_EMAILS = 'erlaubte Emails',
  RETENTION_RATE = 'Einbehaltsquote'
}

export class ImportFileError extends Error {
  constructor(errorMessage: string) {
    super(errorMessage);
  }
}

@Injectable()
export class LocationImportService extends ILocationImportService {
  // services
  private readonly locationApi: ILocationApi;
  private readonly employeeApi: IEmployeeApi;
  private readonly locationService: ILocationService;

  // const
  private readonly RESPONSE_HEADER: CSV_IDENTIFIERS[] = Object.values(CSV_IDENTIFIERS);
  private readonly REQUIRED_FIELDS: CSV_IDENTIFIERS[] = [CSV_IDENTIFIERS.HGB_NUMBER, CSV_IDENTIFIERS.DESCRIPTION, CSV_IDENTIFIERS.MAIN_LOCATION_CN];
  private readonly REQUIRED_FIELDS_RETENTION: CSV_EXTENDED_IDENTIFIERS[] = [CSV_EXTENDED_IDENTIFIERS.PURCHASE_GROUP, CSV_EXTENDED_IDENTIFIERS.RETENTION_RATE];

  constructor(locationApi: ILocationApi,
    employeeApi: IEmployeeApi,
    locationService: ILocationService) {
    super();
    this.locationApi = locationApi;
    this.employeeApi = employeeApi;
    this.locationService = locationService;
  }

  public getLocationsFromFile(file: File): Observable<ILocationRowOfFileDto> {
    const reader: FileReader = new FileReader();
    reader.readAsText(file, AppSettings.CSV_IMPORT_ANSI_ENCODING);

    return fromEvent(reader, 'load').pipe(
      take(1),
      switchMap(_=> from(this.convertFileToLocations(<string>reader.result, this.convertLineToLocation.bind(this))))
    );
  }

  public getRetentionRatesFromFile(file: File): Observable<IRetentionrateRowOfFileDto> {
    const reader: FileReader = new FileReader();
    reader.readAsText(file, AppSettings.CSV_IMPORT_ANSI_ENCODING);

    return fromEvent(reader, 'load').pipe(
      take(1),
      switchMap(_=> from(this.convertFileToRetentionRates(<string>reader.result, this.convertLineToRetentionRate.bind(this))))
    );
  }

  public getFullLocationsFromFile(file: File): Observable<ILocationRowOfFileDto> {
    const reader: FileReader = new FileReader();
    reader.readAsText(file, AppSettings.CSV_IMPORT_ANSI_ENCODING);

    return fromEvent(reader, 'load').pipe(
      take(1),
      switchMap(_=> from(this.convertFileToLocations(<string>reader.result, this.convertLineToFullLocation.bind(this))))
    );
  }

  public importLocations(locations: ILocationImportDto[]): Observable<ILocationBulkCreateResponseDto> {
    return this.locationApi.createLocations({
      Locations: locations
    });
  }

  public importFullLocations(locations: ILocationImportDto[]): Observable<ILocationBulkCreateResponseDto> {
    return this.locationApi.createFullLocations({
      Locations: locations
    });
  }

  public convertResponseToFile(response: ILocationBulkCreateResponseDto): void {
    const fileContent: string[] = this.convertLocationsToFileContent(response.locations);
    const doc: HTMLAnchorElement = document.createElement('a');
    const blobOptions: BlobPropertyBag = {
      type: 'text/csv'
    };
    const blob: Blob = new Blob(fileContent, blobOptions);

    doc.href = URL.createObjectURL(blob);
    doc.download = 'ImportResult.csv';
    doc.click();
  }

  public getDefaultImportData(): ImportData {

    let possibleCSVHeaders: string[] = Object.values(CSV_IDENTIFIERS).filter(identifier => identifier !== CSV_IDENTIFIERS.RESULT);
    if (this.employeeApi.isGlobalAdmin()) {
      possibleCSVHeaders = possibleCSVHeaders.concat(Object.values(CSV_EXTENDED_IDENTIFIERS));
    }
    const possibleCSVHeadersString: string = possibleCSVHeaders.join(', ');

    return this.createDefaultImportData(possibleCSVHeadersString);
  }

  public getRetentionRateImportData(): ImportData {

    const possibleCSVHeaders: string[] = [CSV_EXTENDED_IDENTIFIERS.PURCHASE_GROUP, CSV_EXTENDED_IDENTIFIERS.RETENTION_RATE];
    const possibleCSVHeadersString: string = possibleCSVHeaders.join(', ');

    return this.createDefaultImportData(possibleCSVHeadersString);
  }

  public getImportDataByFile(template: ImportData, file: File): ImportData {
    template.file = file;
    template.fileName = file.name;
    template.fileSize = file.size;
    template.progressBarHidden = false;
    template.saveDisabled = false;
    template.uploadProgress = 0;
    return template;
  }

  public shouldUseFullLocationImport(): Observable<boolean> {
    return this.employeeApi.isGlobalAdmin();
  }

  private convertLocationsToFileContent(locations: ICreateLocationResponseDto[]): string[] {
    const fileContent: string[] = [];

    fileContent.push(this.convertHeadline(this.RESPONSE_HEADER) + '\n');
    locations.forEach(location =>
      //sorting according to CSV_IDENTIFIERS
      fileContent.push(
        [
          location.number,
          location.description,
          location.branch,
          location.mainLocationCn,
          location.isSupplier,
          location.shouldBeShownInKba,
          location.shouldBeShownInDlb,
          location.shouldBeShownInLv,
          location.shouldBeShownInGv,
          this.convertStatusToString(location.response)
        ].join(DELIMITER) + '\n')
    );

    return fileContent;
  }

  private convertFileToLocations(fileContent: string, convertLineFunction: ConvertLineToLocationCallback): ILocationRowOfFileDto[] {
    const csvRows: string[] = fileContent.split(/\r\n|\r|\n/g);
    const headLine: string = csvRows[0].trim();
    const colNames: string[] = headLine.split(';');

    const missingColumns: CSV_IDENTIFIERS[] = this.REQUIRED_FIELDS.filter(identifier => !colNames.includes(identifier));
    if (missingColumns.length > 0) {
      throw new ImportFileError($localize`ImportFileMissingData|The given file is missing the fields: ${missingColumns}`);
    }

    const locations: ILocationImportDto[] = csvRows
      .filter(row => row.length > 0)
      .filter(row => !row.includes(headLine))
      .map(row => convertLineFunction(row, colNames));

    return locations.map((locationData: ILocationImportDto, index: number) =>
    {
      return {
        amountOfEntriesInFile: locations.length,
        locationRow: locationData,
        currentRow: index
      };
    });
  }

  private convertFileToRetentionRates(fileContent: string, convertLineFunction: ConvertLineToRetentionRateCallback): IRetentionrateRowOfFileDto[] {
    const csvRows: string[] = fileContent.split(/\r\n|\r|\n/g);
    const headLine: string = csvRows[0].trim();
    const colNames: string[] = headLine.split(';');

    const missingColumns: CSV_EXTENDED_IDENTIFIERS[] = this.REQUIRED_FIELDS_RETENTION.filter(identifier => !colNames.includes(identifier));
    if (missingColumns.length > 0) {
      throw new ImportFileError($localize`ImportFileMissingData|The given file is missing the fields: ${missingColumns}`);
    }

    const retentionRates: IRetentionRateImportDto[] = csvRows
      .filter(row => row.length > 0)
      .filter(row => !row.includes(headLine))
      .map(row => convertLineFunction(row, colNames));

    return retentionRates.map((retentionRateData: IRetentionRateImportDto, index: number) =>
    {
      return {
        amountOfEntriesInFile: retentionRates.length,
        retentionRateRow: retentionRateData,
        currentRow: index
      };
    });
  }

  // convert all import statuses
  // eslint-disable-next-line complexity
  private convertStatusToString(status: ImportStatus): string {
    switch(status) {
    case ImportStatus.Success:
      return $localize`Import successful|Import successful`;
    case ImportStatus.SuccessUpdate:
      return $localize`UpdateSuccessful|Update successful`;
    case ImportStatus.InProgress:
      return $localize`InProgress|Import couldn't be finished or is still in progress.`;
    case ImportStatus.LdapError:
      return $localize`Ldap error|Ldap error`;
    case ImportStatus.CreateLocationError:
      return $localize`Create location error |Create location error`;
    case ImportStatus.UpdateLocationError:
      return $localize`UpdateLocationError|An Error occurred while updating this location`
    case ImportStatus.UpdateMainLocationError:
      return $localize`Update main location error|Update main location error`;
    case ImportStatus.CreateRoleError:
      return $localize`Create Role error|Create Role error`;
    case ImportStatus.CreateAppLocationError:
      return $localize`CreateAppLocationError|Create App-location error`;
    case ImportStatus.NoDescription:
      return $localize`No description given|No ${CSV_IDENTIFIERS.DESCRIPTION} given`;
    case ImportStatus.NoMainLocation:
      return $localize`Main location not available|Main location not available`;
    case ImportStatus.NoHgbNumber:
      return $localize`HgbNumber missing|${CSV_IDENTIFIERS.HGB_NUMBER} missing`;
    case ImportStatus.UnknownError:
      return $localize`UnknownError|An Error occurred during import`;
    case ImportStatus.CreateMainLocationError:
      return $localize`MainLocationCreateError|Location could not be created as Main location`;
    }
  }

  private convertLineToLocation(line: string, colNames: string[]): ILocationImportDto {
    const cols: string[] = line.split(';');
    return {
      cn: '',
      number: cols[colNames.indexOf(CSV_IDENTIFIERS.HGB_NUMBER)].trim(),
      description: cols[colNames.indexOf(CSV_IDENTIFIERS.DESCRIPTION)].trim(),
      branch: cols[colNames.indexOf(CSV_IDENTIFIERS.HGB_SECTOR)].trim(),
      mainLocation: cols[colNames.indexOf(CSV_IDENTIFIERS.MAIN_LOCATION_CN)].trim(),
      isSupplier: this.locationService.isSupplierLocation(cols[colNames.indexOf(CSV_IDENTIFIERS.HGB_NUMBER)].trim()),
      employeeCount: 0,
      delAdmins: [],
      contracts: [],
      isIncomplete: true,
      isActive: true,
      shouldBeShownInKba: this.mapFileValueToBoolean(cols[colNames.indexOf(CSV_IDENTIFIERS.SHOULD_BE_SHOWN_IN_KBA)].trim()),
      shouldBeShownInDlb: this.mapFileValueToBoolean(cols[colNames.indexOf(CSV_IDENTIFIERS.SHOULD_BE_SHOWN_IN_DLB)].trim()),
      shouldBeShownInLv: this.mapFileValueToBoolean(cols[colNames.indexOf(CSV_IDENTIFIERS.SHOULD_BE_SHOWN_IN_LV)].trim()),
      shouldBeShownInGv: this.mapFileValueToBoolean(cols[colNames.indexOf(CSV_IDENTIFIERS.SHOULD_BE_SHOWN_IN_GV)].trim()),
      allowedEmailDomains: [],
      allowedEmailAddresses: []
    };
  }

  private convertLineToRetentionRate(line: string, colNames: string[]): IRetentionRateImportDto {
    const cols: string[] = line.split(';');
    return {
      retentionRate: cols[colNames.indexOf(CSV_EXTENDED_IDENTIFIERS.RETENTION_RATE)].trim(),
      purchaseGroup: cols[colNames.indexOf(CSV_EXTENDED_IDENTIFIERS.PURCHASE_GROUP)].trim(),
    };
  }

  // simple mapping function
  // eslint-disable-next-line max-lines-per-function
  private convertLineToFullLocation(line: string, colNames: string[]): IFullLocationImportDto {
    const cols: string[] = line.split(';');
    return {
      cn: '',
      number: cols[colNames.indexOf(CSV_IDENTIFIERS.HGB_NUMBER)]?.trim(),
      description: cols[colNames.indexOf(CSV_IDENTIFIERS.DESCRIPTION)]?.trim(),
      branch: cols[colNames.indexOf(CSV_IDENTIFIERS.HGB_SECTOR)]?.trim(),
      mainLocation: cols[colNames.indexOf(CSV_IDENTIFIERS.MAIN_LOCATION_CN)]?.trim(),
      isSupplier: this.locationService.isSupplierLocation(cols[colNames.indexOf(CSV_IDENTIFIERS.HGB_NUMBER)]?.trim()),
      street: cols[colNames.indexOf(CSV_EXTENDED_IDENTIFIERS.STREET)]?.trim(),
      postCode: cols[colNames.indexOf(CSV_EXTENDED_IDENTIFIERS.POST_CODE)]?.trim(),
      city: cols[colNames.indexOf(CSV_EXTENDED_IDENTIFIERS.CITY)]?.trim(),
      region: cols[colNames.indexOf(CSV_EXTENDED_IDENTIFIERS.REGION)]?.trim(),
      country: cols[colNames.indexOf(CSV_EXTENDED_IDENTIFIERS.COUNTRY)]?.trim(),
      telephone: cols[colNames.indexOf(CSV_EXTENDED_IDENTIFIERS.TELEPHONE)]?.trim(),
      fax: cols[colNames.indexOf(CSV_EXTENDED_IDENTIFIERS.FAX)]?.trim(),
      email: cols[colNames.indexOf(CSV_EXTENDED_IDENTIFIERS.EMAIL)]?.trim(),
      shortName: cols[colNames.indexOf(CSV_EXTENDED_IDENTIFIERS.SHORT_NAME)]?.trim(),
      website: cols[colNames.indexOf(CSV_EXTENDED_IDENTIFIERS.WEBSITE)]?.trim(),
      tradeId: cols[colNames.indexOf(CSV_EXTENDED_IDENTIFIERS.TRADE_ID)]?.trim(),
      gln: cols[colNames.indexOf(CSV_EXTENDED_IDENTIFIERS.GLN)]?.trim(),
      searchTopic: cols[colNames.indexOf(CSV_EXTENDED_IDENTIFIERS.SEARCH_TOPIC)]?.trim(),
      clerkFirstName: cols[colNames.indexOf(CSV_EXTENDED_IDENTIFIERS.CLERK_FIRSTNAME)]?.trim(),
      clerkSurName: cols[colNames.indexOf(CSV_EXTENDED_IDENTIFIERS.CLERK_SURNAME)]?.trim(),
      clerkEmail: cols[colNames.indexOf(CSV_EXTENDED_IDENTIFIERS.CLERK_EMAIL)]?.trim(),
      clerkTelephone: cols[colNames.indexOf(CSV_EXTENDED_IDENTIFIERS.CLERK_TELEPHONE)]?.trim(),
      clerkFax: cols[colNames.indexOf(CSV_EXTENDED_IDENTIFIERS.CLERK_FAX)]?.trim(),
      clerkDepartment: cols[colNames.indexOf(CSV_EXTENDED_IDENTIFIERS.CLERK_DEPARTMENT)]?.trim(),
      clerkPurchaseGroup: cols[colNames.indexOf(CSV_EXTENDED_IDENTIFIERS.CLERK_PURCHASE_GROUP)]?.trim(),
      supplierClassification: cols[colNames.indexOf(CSV_EXTENDED_IDENTIFIERS.SUPPLIER_CLASSIFICATION)]?.trim(),
      purchaseDepartment: cols[colNames.indexOf(CSV_EXTENDED_IDENTIFIERS.PURCHASE_DEPARTMENT)]?.trim(),
      purchaseGroup: cols[colNames.indexOf(CSV_EXTENDED_IDENTIFIERS.PURCHASE_GROUP)]?.trim(),
      assortment: cols[colNames.indexOf(CSV_EXTENDED_IDENTIFIERS.ASSORTMENT)]?.trim(),
      centralDeletionFlag: this.mapFileValueToBoolean(cols[colNames.indexOf(CSV_EXTENDED_IDENTIFIERS.CENTRAL_DELETION_FLAG)]?.trim()),
      eventITKNVVPRAT2: this.mapFileValueToBoolean(cols[colNames.indexOf(CSV_EXTENDED_IDENTIFIERS.Event_IT_KNVV_PRAT2)]?.trim()),
      legallyIndependentZ000025: this.mapFileValueToBoolean(cols[colNames.indexOf(CSV_EXTENDED_IDENTIFIERS.LEGALLY_INDEPENDENT_Z000025)]?.trim()),
      employeeCount: 0,
      delAdmins: [],
      contracts: [],
      isIncomplete: true,
      isActive: true,
      shouldBeShownInKba: this.mapFileValueToBoolean(cols[colNames.indexOf(CSV_IDENTIFIERS.SHOULD_BE_SHOWN_IN_KBA)]?.trim()),
      shouldBeShownInDlb: this.mapFileValueToBoolean(cols[colNames.indexOf(CSV_IDENTIFIERS.SHOULD_BE_SHOWN_IN_DLB)]?.trim()),
      shouldBeShownInLv: this.mapFileValueToBoolean(cols[colNames.indexOf(CSV_IDENTIFIERS.SHOULD_BE_SHOWN_IN_LV)]?.trim()),
      shouldBeShownInGv: this.mapFileValueToBoolean(cols[colNames.indexOf(CSV_IDENTIFIERS.SHOULD_BE_SHOWN_IN_GV)]?.trim()),
      deliveryLock: this.mapFileValueToBoolean(cols[colNames.indexOf(CSV_EXTENDED_IDENTIFIERS.DELIVERY_LOCK)]?.trim()),
      bookingLock: this.mapFileValueToBoolean(cols[colNames.indexOf(CSV_EXTENDED_IDENTIFIERS.BOOKING_LOCK)]?.trim()),
      allowedEmailDomains: this.mapFileValueToArray(cols[colNames.indexOf(CSV_EXTENDED_IDENTIFIERS.ALLOWED_DOMAINS)]?.trim()),
      allowedEmailAddresses: this.mapFileValueToArray(cols[colNames.indexOf(CSV_EXTENDED_IDENTIFIERS.ALLOWED_EMAILS)]?.trim())
    };
  }

  private convertHeadline(header: string[]) {
    return header.join(DELIMITER)
  }

  private mapFileValueToBoolean(csvValue: string|undefined, defaultValue: boolean = false): boolean {
    return csvValue ? TRUTHY_CSV_VALUES.includes(csvValue.toLowerCase()) : defaultValue;
  }

  private mapFileValueToArray(csvValue: string, defaultValue: string[] = []): string[] {
    return csvValue ? csvValue.split(/,|\|/).map(value => value.trim()) : defaultValue;
  }

  private createDefaultImportData(possibleCSVHeadersString: string): ImportData {
    const csvUploadText: string = $localize`Text upload-area: Use drag & drop or click to choose csv file to upload|Text upload-area: Use drag & drop or click to choose csv file to upload.`;
    const csvHeadersHint: string = $localize`LocationImportFieldsText|The CSV can have the values for: ${possibleCSVHeadersString}`;
    return ({
      isModalOpen: false,
      file: <File>{
      },
      fileName: 'test.csv',
      fileSize: 0,
      uploadProgress: 0,
      progressBarHidden: true,
      uploadText: csvUploadText + ' ' + csvHeadersHint,
      uploadDisabled: false,
      saveDisabled: true,
    })
  }
}
