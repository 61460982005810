import {
  ActionCell,
  Cell,
  CellType,
  CheckboxCell,
  FilterData,
  Icon,
  ImageSize,
  ImageTextCell,
  ImageType,
  LabelType,
  LocationDetailEditData,
  MoreButtonElement,
  MoreButtonElementType,
  PmInputData,
  PmMultiSelectInputData,
  PmInputFieldData,
  PmInputType,
  PmMenuActionData,
  PmMenuActionType,
  PmSearchInputFieldData,
  Row,
  StatusCell,
  TextCell,
  IMultiselectFieldData,
  PmInputFieldSizeType,
  PmDropdownInputFieldData,
  PaginationControlData
} from 'hagebau-coremedia';
import {
  Injectable
} from '@angular/core';
import {
  ILocationDetailDto,
  ILocationDto,
  ILocationFilterDto,
  ILocationRequestDto,
  ILocationSortDto,
  ILocationTableService,
  IMainLocationDto,
  LocationSortKeys
} from './index';
import {
  LdapSortDirection
} from '../LdapSortDirection';
import {
  IBaseContractDto
} from '../contract';
import {
  IFilterDto
} from '../IFilterDto';
import {
  EmployeeState,
  IBaseEmployeeDto,
} from '../employee';
import {
  AppSettings
} from '../appSettings/appSettings';
import {
  IPaginationService
} from '../pagination/ipagination.service';
import {
  IAuthService
} from '../account/iauth.service';
import {
  ICurrentPageInfo
} from '../pagination/IPaginationInfoDto';
import {
  ISearchInfo
} from '../search/search.service';
import * as locationConstants from './location-data';


export interface LocationEditViewData {
  basicInfo: PmInputFieldData[],
  displayOnlyInfo: PmInputFieldData[],
  singleLineInfo: IMultiselectFieldData[],
  isSupplier: boolean,
  isActive: boolean,
  delAdmins: Row[],
  contracts: IBaseContractDto[],
  shouldBeShownInKba: boolean,
  shouldBeShownInDlb: boolean,
  shouldBeShownInLv: boolean,
  shouldBeShownInGv: boolean,
  legallyIndependentZ000025: boolean,
  centralDeletionFlag: boolean,
  eventITKNVVPRAT2: boolean,
}

/*eslint max-lines: ['error', 1000]*/
//increased max lines, as this file contains a lot of configurations with low cognitive complexity impact
@Injectable()
export class LocationTableService extends ILocationTableService {
  private readonly authService: IAuthService;
  private readonly paginationService: IPaginationService;

  constructor(
    authService: IAuthService,
    paginationService: IPaginationService
  ) {
    super();
    this.authService = authService;
    this.paginationService = paginationService;
  }

  public mapLocationsToRows(locations: ILocationDto[]): Row[] {
    return locations.map((location: ILocationDto) => {
      return {
        rowId: location.cn.toString(),
        checked: false,
        cells: this.mapLocationToRow(location),
        warning: location.isIncomplete,
        inactive: !location.isActive
      };
    });
  }

  public mapLocationsToMinimalRows(locations: ILocationDto[]): Row[] {
    return locations.map((location: ILocationDto) => {
      return {
        rowId: location.cn.toString(),
        checked: false,
        cells: this.mapLocationToMinimalRow(location),
        warning: location.isIncomplete,
        inactive: false
      };
    });
  }

  public getMenuActions(): PmMenuActionData[] {
    return [
      {
        actionName: $localize`Delete|Delete`,
        icon: Icon.TRASH,
        type: PmMenuActionType.DELETE
      },
      {
        actionName: $localize`Activate|Activate`,
        icon: Icon.CIRCLE_CHECK,
        type: PmMenuActionType.ACTIVATE
      },
      {
        actionName: $localize`Deactivate|Deactivate`,
        icon: Icon.CIRCLE_CLOSE,
        type: PmMenuActionType.DEACTIVATE
      },
    ];
  }

  public mapLocationRequestDto(
    filters: FilterData[],
    searchInfo: ISearchInfo,
    sortInfo: ILocationSortDto,
    pageInfo: ICurrentPageInfo
  ): ILocationRequestDto {
    return {
      Filters: this.mapActiveFilters(filters),
      SearchTerms: searchInfo.searchTerms,
      SearchFields: searchInfo.searchFields,
      SortParams: {
        SortKey: sortInfo.SortKey,
        SortDirection: sortInfo.SortDirection
      },
      Pagination: {
        Page: pageInfo.currentPage,
        PageAmount: pageInfo.currentPageSize
      }
    };
  }

  public mapLocationMinimalRequestDto(searchTerms: string[], searchFields: string[] = AppSettings.LOCATION_SEARCH_FIELDS, filters: FilterData[] = []): ILocationRequestDto {
    return this.mapLocationRequestDto(
      filters,
      {
        searchTerms: searchTerms,
        searchFields: searchFields
      },
      {
        SortKey: LocationSortKeys.None,
        SortDirection: LdapSortDirection.Ascending
      },
      {
        currentPage: -1,
        currentPageSize: -1
      }
    )
  }

  //just a configuration method
  // eslint-disable-next-line max-lines-per-function
  public mapLocationToEditViewData(location: ILocationDetailDto): LocationEditViewData {
    return {
      basicInfo: [
        {
          label: $localize`description|Description`,
          fieldName: 'description',
          value: location.description,
          editable: true,
          type: PmInputType.DEFAULT,
          required: true,
          inputData: []
        },
        {
          label: $localize`number|Number`,
          fieldName: 'number',
          value: location.number,
          editable: true,
          type: PmInputType.DEFAULT,
          required: true,
          inputData: []
        },
        {
          label: $localize`branch|Branch`,
          fieldName: 'branch',
          value: location.branch,
          type: PmInputType.DEFAULT,
          required: false,
          editable: true,
          inputData: []
        },
        {
          label:  $localize`mainLocation|Main Location`,
          fieldName: 'mainLocation',
          value: location.mainLocation !== null ? this.formatMainLocation(location.mainLocation?.number, location.mainLocation?.description) : '',
          editable: true,
          identifier: location.mainLocation !== null ? location.mainLocation?.cn : '',
          type: PmInputType.SEARCH,
          required: true,
          inputData: [],
          searchSuggestionsVisible: false
        } as PmSearchInputFieldData,
        {
          label: $localize`salesChannel|Sales Channel`,
          fieldName: 'salesChannel',
          value: location.salesChannel,
          editable: true,
          identifier: 'salesChannel',
          type: PmInputType.DROPDOWN,
          required: false,
          inputData: [
            {
              key: '0',
              value: $localize`retailCategory|Retail`
            },
            {
              key: '1',
              value: $localize`specialistCategory|Specialist`
            }
          ]
        } as PmDropdownInputFieldData,
        {
          label: $localize`country|Country`,
          fieldName: 'country',
          value: location.country,
          editable: true,
          type: PmInputType.SEARCH,
          required: false,
          searchSuggestionsVisible: false,
          inputData: locationConstants.COUNTRY_OPTIONS
        } as PmSearchInputFieldData,
      ],
      displayOnlyInfo: [
        {
          label: $localize`street|Street`,
          fieldName: 'street',
          value: location.street,
          type: PmInputType.DEFAULT,
          required: false,
          editable: false,
          inputData: []
        },
        {
          label: $localize`postCode|PostCode`,
          fieldName: 'postCode',
          value: location.postCode,
          type: PmInputType.DEFAULT,
          required: false,
          editable: false,
          inputData: []
        },
        {
          label: $localize`city|City`,
          fieldName: 'city',
          value: location.city,
          type: PmInputType.DEFAULT,
          required: false,
          editable: false,
          inputData: []
        },
        {
          label: $localize`region|Region`,
          fieldName: 'region',
          value: location.region,
          type: PmInputType.DEFAULT,
          required: false,
          editable: false,
          inputData: []
        },
        {
          label: $localize`telephone|Telephone`,
          fieldName: 'telephone',
          value: location.telephone,
          type: PmInputType.DEFAULT,
          required: false,
          editable: false,
          inputData: []
        },
        {
          label: $localize`fax|Fax`,
          fieldName: 'fax',
          value: location.fax,
          type: PmInputType.DEFAULT,
          required: false,
          editable: false,
          inputData: []
        },
        {
          label: $localize`email|Email`,
          fieldName: 'email',
          value: location.email,
          type: PmInputType.DEFAULT,
          required: false,
          editable: false,
          inputData: []
        },
        {
          label: $localize`shortName|ShortName`,
          fieldName: 'shortName',
          value: location.shortName,
          type: PmInputType.DEFAULT,
          required: false,
          editable: false,
          inputData: []
        },
        {
          label: $localize`website|Website`,
          fieldName: 'website',
          value: location.website,
          type: PmInputType.DEFAULT,
          required: false,
          editable: false,
          inputData: []
        },
        {
          label: $localize`tradeId|TradeId`,
          fieldName: 'tradeId',
          value: location.tradeId,
          type: PmInputType.DEFAULT,
          required: false,
          editable: false,
          inputData: []
        },
        {
          label: $localize`gln|GLN`,
          fieldName: 'gln',
          value: location.gln,
          type: PmInputType.DEFAULT,
          required: false,
          editable: false,
          inputData: []
        },
        {
          label: $localize`searchTopic|SearchTopic`,
          fieldName: 'searchTopic',
          value: location.searchTopic,
          type: PmInputType.DEFAULT,
          required: false,
          editable: false,
          inputData: []
        },
        {
          label: $localize`clerkName|ClerkName`,
          fieldName: 'clerkName',
          value: location.clerkName,
          type: PmInputType.DEFAULT,
          required: false,
          editable: false,
          inputData: []
        },
        {
          label: $localize`clerkEmail|ClerkEmail`,
          fieldName: 'clerkEmail',
          value: location.clerkEmail,
          type: PmInputType.DEFAULT,
          required: false,
          editable: false,
          inputData: []
        },
        {
          label: $localize`clerkTelephone|ClerkTelephone`,
          fieldName: 'clerkTelephone',
          value: location.clerkTelephone,
          type: PmInputType.DEFAULT,
          required: false,
          editable: false,
          inputData: []
        },
        {
          label: $localize`clerkFax|ClerkFax`,
          fieldName: 'clerkFax',
          value: location.clerkFax,
          type: PmInputType.DEFAULT,
          required: false,
          editable: false,
          inputData: []
        },
        {
          label: $localize`clerkDepartment|ClerkDepartment`,
          fieldName: 'clerkDepartment',
          value: location.clerkDepartment,
          type: PmInputType.DEFAULT,
          required: false,
          editable: false,
          inputData: []
        },
        {
          label: $localize`clerkPurchaseGroup|ClerkPurchaseGroup`,
          fieldName: 'clerkPurchaseGroup',
          value: location.clerkPurchaseGroup,
          type: PmInputType.DEFAULT,
          required: false,
          editable: false,
          inputData: []
        },
        {
          label: $localize`clerkPurchaseName|ClerkPurchaseName`,
          fieldName: 'clerkPurchaseName',
          value: location.clerkPurchaseName,
          type: PmInputType.DEFAULT,
          required: false,
          editable: false,
          inputData: []
        },
        {
          label: $localize`supplierClassification|SupplierClassification`,
          fieldName: 'supplierClassification',
          value: location.supplierClassification,
          type: PmInputType.DEFAULT,
          required: false,
          editable: false,
          inputData: []
        },
        {
          label: $localize`purchaseDepartment|PurchaseDepartment`,
          fieldName: 'purchaseDepartment',
          value: location.purchaseDepartment,
          type: PmInputType.DEFAULT,
          required: false,
          editable: false,
          inputData: []
        },
        {
          label: $localize`assortment|Assortment`,
          fieldName: 'assortment',
          value: location.assortment,
          type: PmInputType.DEFAULT,
          required: false,
          editable: false,
          inputData: []
        },
      ],
      singleLineInfo: [{
        label: $localize`Alliances|Alliances`,
        active: false,
        disabled: false,
        size: PmInputFieldSizeType.DEFAULT,
        text: '',
        fieldName: 'alliances',
        placeholder: $localize`Assign Alliances|Assign Alliances`,
        options: [],
        selections: location.assignedAllianceCns.map(assignedAllianceCn => <PmMultiSelectInputData>{
          key: assignedAllianceCn, value: assignedAllianceCn
        }),
      }],
      isSupplier: location.isSupplier,
      isActive: location.isActive,
      shouldBeShownInKba: location.shouldBeShownInKba,
      shouldBeShownInDlb: location.shouldBeShownInDlb,
      shouldBeShownInLv: location.shouldBeShownInLv,
      shouldBeShownInGv: location.shouldBeShownInGv,
      legallyIndependentZ000025: location.legallyIndependentZ000025,
      centralDeletionFlag: location.centralDeletionFlag,
      eventITKNVVPRAT2: location.eventITKNVVPRAT2,
      delAdmins: this.mapDelAdminsToRows(location.delAdmins),
      contracts: location.contracts
    };
  }

  public formatMainLocation(locationNumber: string | undefined, description: string | undefined): string {
    return locationNumber + ' ' + description
  }

  // mapping with low cognitive complexity
  // eslint-disable-next-line complexity, max-lines-per-function
  public mapEditDataToLocation(editData: LocationDetailEditData, assignedContracts: IBaseContractDto[], searchSuggestions: IMainLocationDto[]): ILocationDetailDto {
    const mainLocationField: PmSearchInputFieldData = editData.basicInfo.find(field => field.fieldName === 'mainLocation') as PmSearchInputFieldData;
    return {
      cn: '', delAdmins: [],
      branch: editData.basicInfo.find(field => field.fieldName === 'branch')?.value || '',
      description: editData.basicInfo.find(field => field.fieldName === 'description')?.value || '',
      mainLocation: (searchSuggestions.find(loc => loc.cn === mainLocationField.identifier)) || null,
      number: editData.basicInfo.find(field => field.fieldName === 'number')?.value || '',
      country: editData.basicInfo.find(field => field.fieldName === 'country')?.value || '',
      contracts: assignedContracts,
      isSupplier: editData.isSupplier,
      salesChannel: (<PmDropdownInputFieldData>editData.basicInfo.find(field => field.fieldName === 'salesChannel')).value || '',
      street: editData.viewOnlyInfo.find(field => field.fieldName === 'street')?.value || '',
      postCode: editData.viewOnlyInfo.find(field => field.fieldName === 'postCode')?.value || '',
      city: editData.viewOnlyInfo.find(field => field.fieldName === 'city')?.value || '',
      region: editData.viewOnlyInfo.find(field => field.fieldName === 'region')?.value || '',
      telephone: editData.viewOnlyInfo.find(field => field.fieldName === 'telephone')?.value || '',
      fax: editData.viewOnlyInfo.find(field => field.fieldName === 'fax')?.value || '',
      email: editData.viewOnlyInfo.find(field => field.fieldName === 'email')?.value || '',
      shortName: editData.viewOnlyInfo.find(field => field.fieldName === 'shortName')?.value || '',
      website: editData.viewOnlyInfo.find(field => field.fieldName === 'website')?.value || '',
      tradeId: editData.viewOnlyInfo.find(field => field.fieldName === 'tradeId')?.value || '',
      gln: editData.viewOnlyInfo.find(field => field.fieldName === 'gln')?.value || '',
      searchTopic: editData.viewOnlyInfo.find(field => field.fieldName === 'searchTopic')?.value || '',
      clerkName: editData.viewOnlyInfo.find(field => field.fieldName === 'clerkName')?.value || '',
      clerkEmail: editData.viewOnlyInfo.find(field => field.fieldName === 'clerkEmail')?.value || '',
      clerkTelephone: editData.viewOnlyInfo.find(field => field.fieldName === 'clerkTelephone')?.value || '',
      clerkFax: editData.viewOnlyInfo.find(field => field.fieldName === 'clerkFax')?.value || '',
      clerkDepartment: editData.viewOnlyInfo.find(field => field.fieldName === 'clerkDepartment')?.value || '',
      clerkPurchaseGroup: editData.viewOnlyInfo.find(field => field.fieldName === 'clerkPurchaseGroup')?.value || '',
      clerkPurchaseName: editData.viewOnlyInfo.find(field => field.fieldName === 'clerkPurchaseName')?.value || '',
      supplierClassification: editData.viewOnlyInfo.find(field => field.fieldName === 'supplierClassification')?.value || '',
      purchaseDepartment: editData.viewOnlyInfo.find(field => field.fieldName === 'purchaseDepartment')?.value || '',
      purchaseGroup: editData.viewOnlyInfo.find(field => field.fieldName === 'purchaseGroup')?.value || '',
      assortment: editData.viewOnlyInfo.find(field => field.fieldName === 'assortment')?.value || '',
      legallyIndependentZ000025: editData.legallyIndependentZ000025,
      assignedAllianceCns: editData.singleLineInfo.find(field => field.fieldName === 'alliances')?.selections.map(entry => entry.value) || [],
      isActive: editData.isActive,
      centralDeletionFlag: editData.centralDeletionFlag,
      eventITKNVVPRAT2: editData.eventITKNVVPRAT2 || false,
      shouldBeShownInKba: editData.shouldBeShownInKba,
      shouldBeShownInDlb: editData.shouldBeShownInDlb,
      shouldBeShownInLv: editData.shouldBeShownInLv,
      shouldBeShownInGv: editData.shouldBeShownInGv,
      contactPersons: editData.viewOnlyInfo.find(field => field.fieldName === 'contactPersons')?.value || '',
      allowedEmailDomains: LocationTableService.splitEmailDomains(editData.basicInfo.find(field => field.fieldName === 'allowedEmailDomains')?.value || ''),
      allowedEmailAddresses: LocationTableService.splitEmailDomains(editData.basicInfo.find(field => field.fieldName === 'allowedEmailAddresses')?.value || '')
    };
  }

  public mapLocationToRow(location: ILocationDto): Cell[] {
    return [
      {
        value: false,
        type: CellType.CHECKBOX_CELL
      } as CheckboxCell,
      {
        text: location.number.toString(),
        type: CellType.TEXT_CELL
      } as TextCell,
      {
        text: location.description,
        type: CellType.TEXT_CELL
      } as TextCell,
      {
        text: location.branch,
        type: CellType.TEXT_CELL
      } as TextCell,
      {
        text: location.mainLocation,
        type: CellType.TEXT_CELL
      } as TextCell,
      {
        text: location.contracts ? location.contracts.map(contract => contract.name).join(', ') : '-',
        type: CellType.TEXT_CELL
      } as TextCell,
      {
        text: location.employeeCount.toString(),
        type: CellType.TEXT_CELL
      } as TextCell,
      {
        text: location.delAdmins?.length.toString() || 0,
        type: CellType.TEXT_CELL
      } as TextCell,
      {
        actions: this.getRowActions(location),
        type: CellType.ACTION_CELL
      } as ActionCell
    ];
  }

  public mapLocationToMinimalRow(location: ILocationDto): Cell[] {
    return [
      {
        value: false,
        type: CellType.CHECKBOX_CELL
      } as CheckboxCell,
      {
        text: location.number.toString(),
        type: CellType.TEXT_CELL
      } as TextCell,
      {
        text: location.description,
        type: CellType.TEXT_CELL
      } as TextCell,
      {
        text: location.mainLocation,
        type: CellType.TEXT_CELL
      } as TextCell,
      {
        text: location.contracts ? location.contracts.map(contract => contract.name).join(', ') : '-',
        type: CellType.TEXT_CELL
      } as TextCell
    ];
  }

  public mapLocationContractsToRows(contracts: IBaseContractDto[]): Row[] {
    return contracts.map((contract: IBaseContractDto) => {
      return {
        rowId: String(contract.cn),
        checked: false,
        cells: this.mapContractToRow(contract),
        warning: false,
        inactive: false
      };
    });
  }

  public mapContractToRow(contract: IBaseContractDto): Cell[] {
    return [
      {
        value: false,
        type: CellType.CHECKBOX_CELL
      } as CheckboxCell,
      {
        text: contract.name,
        type: CellType.TEXT_CELL
      } as TextCell,
      {
        text: contract.category,
        type: CellType.TEXT_CELL
      } as TextCell
    ];
  }

  private mapDelAdminsToRows(delAdmins: IBaseEmployeeDto[]): Row[] {
    return delAdmins.map((admin: IBaseEmployeeDto, index: number) => {
      return {
        rowId: String(index),
        checked: false,
        cells: this.mapDelAdminToRow(admin),
        warning: false,
        inactive: false
      };
    });
  }

  private mapDelAdminToRow(admin: IBaseEmployeeDto): Cell[] {
    return [
      {
        text: admin.givenName + (admin.surName ? ' ' + admin.surName : ''),
        image: {
          size: ImageSize.XXXS,
          type: ImageType.ROUND,
          imageSources: [{
            path: '/assets/default_employee.jpg',
            width: '900w'
          }],
          base64Image: admin.photo,
          imageSizeRules: ['100vw']
        },
        type: CellType.IMAGE_TEXT_CELL,
        cellId: ''
      } as ImageTextCell,
      {
        text: admin.email,
        type: CellType.TEXT_CELL
      } as TextCell,
      {
        status: LocationTableService.mapStateToLabel(admin.status),
        type: CellType.STATUS_CELL
      } as StatusCell
    ];
  }

  public static filterCountryOptions(searchTerm: string): PmInputData[] {
    return locationConstants.COUNTRY_OPTIONS.filter(country => {
      return country['value'].toLowerCase().startsWith(searchTerm.toLowerCase());
    });
  }

  public toFilterDto(filterValue: string, isNotFilter: boolean = false): IFilterDto {
    return {
      Value: filterValue,
      IsNotFilter: isNotFilter
    };
  }

  public getPagination(): PaginationControlData {
    return {
      current: 1,
      nextAmount: 2,
      previousAmount: 0,
      resultRange: '1-10',
      resultTotal: '500',
      nextDisabled: false,
      prevDisabled: true
    };
  }

  public getPageSizes(): PmInputData[] {
    return [
      {
        key: '1', value: '10'
      },
      {
        key: '2', value: '20'
      },
      {
        key: '3', value: '50'
      }
    ];
  }

  public getPaginationService(): IPaginationService {
    return this.paginationService
  }

  private mapActiveFilters(filters: FilterData[]): ILocationFilterDto {
    return {
      Cn: filters.filter(filter => filter.identifier.toLowerCase() === 'cn').map(filter => this.toFilterDto(filter.value, filter.isNotFilter)),
      Branch: filters.filter(filter => filter.identifier.toLowerCase() === 'branch').map(filter => this.toFilterDto(filter.value, filter.isNotFilter)),
      Description: filters.filter(filter => filter.identifier.toLowerCase() === 'description').map(filter => this.toFilterDto(filter.value, filter.isNotFilter)),
      MainLocation: filters.filter(filter => filter.identifier.toLowerCase() === 'mainlocation').map(filter => this.toFilterDto(filter.value, filter.isNotFilter)),
      Number: filters.filter(filter => filter.identifier.toLowerCase() === 'number').map(filter => this.toFilterDto(filter.value, filter.isNotFilter)),
      Contract: filters.filter(filter => filter.identifier.toLowerCase() === 'contract').map(filter => this.toFilterDto(filter.value, filter.isNotFilter))
    };
  }

  private getRowActions(location: ILocationDto): MoreButtonElement[] {
    const isServiceAdmin: boolean = this.authService.isServiceAdmin();
    return [
      {
        moreBtnId: '1',
        icon: Icon.CIRCLE_INFO,
        text: $localize`View|View`,
        link: '',
        type: MoreButtonElementType.VIEW,
        disabled: false
      },
      ...!this.authService.isDelAdminOnly() ?
        [
          {
            moreBtnId: '2',
            icon: Icon.PEN,
            text: $localize`Edit|Edit`,
            link: '',
            type: MoreButtonElementType.EDIT,
            disabled: isServiceAdmin
          },
          {
            moreBtnId: '3',
            icon: Icon.TRASH,
            text: $localize`Delete|Delete`,
            link: '',
            type: MoreButtonElementType.DELETE,
            disabled: isServiceAdmin
          },
          {
            moreBtnId: '4',
            icon: location.isActive ?  Icon.CIRCLE_CLOSE : Icon.CIRCLE_CHECK,
            text: location.isActive ?  $localize`Deactivate|Deactivate` : $localize`Activate|Activate`,
            link: '',
            type: location.isActive ?  MoreButtonElementType.DEACTIVATE : MoreButtonElementType.ACTIVATE,
            disabled: isServiceAdmin
          },
        ] : [],
    ];
  }

  public static createSearchRequestDto(searchTerms: string[], searchFields: string[]): ILocationRequestDto {
    return {
      Filters: {
        Cn: [],
        Branch: [],
        MainLocation: [],
        Description: [],
        Number: [],
        Contract: []
      },
      SearchTerms: searchTerms,
      SearchFields: searchFields,
      SortParams: {
        SortKey: LocationSortKeys.None,
        SortDirection: LdapSortDirection.Ascending
      },
      Pagination: {
        Page: 1,
        PageAmount: AppSettings.INITIAL_PAGE_SIZE
      }
    }
  }

  private static mapStateToLabel(state: EmployeeState): LabelType {
    switch (state) {
    case EmployeeState.Active:
      return LabelType.ACTIVE;
    case EmployeeState.Inactive:
      return LabelType.INACTIVE;
    case EmployeeState.Locked:
      return LabelType.LOCKED;
    }
  }

  private static splitEmailDomains(allowedEmailDomainsString: string): string[] {
    if (!allowedEmailDomainsString) {
      return [];
    }
    return allowedEmailDomainsString.split(',').map(domain => domain.trim());
  }
}
