import {
  z
} from 'zod';
import {
  AddRetentionRateResponseDtoSchema
} from './ilocation-import-response.dto';

export const RetentionRateBulkUpdateResponseDtoSchema = z.object({ // eslint-disable-line @typescript-eslint/typedef
  rates: z.array(AddRetentionRateResponseDtoSchema),
})

export type IRetentionRateBulkUpdateResponseDto = z.infer<typeof RetentionRateBulkUpdateResponseDtoSchema>;
