import {
  ILocationDto
} from './ilocation.dto';

export interface ILocationImportDto extends  ILocationDto {
  isSupplier: boolean;
  shouldBeShownInKba: boolean;
  shouldBeShownInDlb: boolean;
  shouldBeShownInLv: boolean;
  shouldBeShownInGv: boolean;
}

export interface IFullLocationImportDto extends ILocationImportDto {
  street: string;
  postCode: string;
  city: string;
  region: string;
  country: string;
  telephone: string;
  fax: string;
  email: string;
  shortName: string;
  website: string;
  tradeId: string;
  gln: string;
  searchTopic: string;
  clerkFirstName: string;
  clerkSurName: string;
  clerkEmail: string;
  clerkTelephone: string;
  clerkFax: string;
  clerkDepartment: string;
  clerkPurchaseGroup: string;
  supplierClassification: string;
  purchaseDepartment: string;
  purchaseGroup: string;
  assortment: string;
  centralDeletionFlag: boolean,
  eventITKNVVPRAT2: boolean,
  legallyIndependentZ000025: boolean,
  deliveryLock: boolean,
  bookingLock: boolean
}

export interface ILocationCreateBulkRequestDto {
  Locations: ILocationImportDto[]
}

export interface ILocationRowOfFileDto {
  amountOfEntriesInFile: number,
  locationRow: ILocationImportDto,
  currentRow: number
}

export interface IRetentionrateRowOfFileDto {
  amountOfEntriesInFile: number,
  retentionRateRow: IRetentionRateImportDto,
  currentRow: number
}

export interface IRetentionRateImportDto {
  retentionRate: string,
  purchaseGroup: string
}
