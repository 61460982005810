import {
  Injectable
} from '@angular/core';
import {
  Observable,
} from 'rxjs';
import {
  IAppPermissionService
} from './iappPermission.service';
import {
  IAppPermissionMinimalDto
} from './IAppPermissionMinimalDto';
import {
  IAppPermissionApi
} from './iappPermission-api.service';
import {
  Icon
} from 'hagebau-coremedia';
import {
  ApplicationName
} from './application-name.enum';
import {
  PermissionName
} from './permission-name.enum';
import {
  FullApplicationName
} from './full-application-name.enum';

@Injectable()
export class AppPermissionService extends IAppPermissionService {
  private readonly appPermissionApi: IAppPermissionApi;


  constructor(
    appPermissionApi: IAppPermissionApi,
  ) {
    super();
    this.appPermissionApi = appPermissionApi;
  }


  public getFullList(): Observable<IAppPermissionMinimalDto[]> {
    return this.checkAndThrowDefaultError(
      this.appPermissionApi.getFullAppPermissions()
    );
  }

  public getList(): Observable<IAppPermissionMinimalDto[]> {
    return this.checkAndThrowDefaultError(
      this.appPermissionApi.getAppPermissions()
    );
  }

  public getLocalizedAppLabel(appName: string): string {
    // eslint-disable-next-line complexity
    return (() => {
      switch (appName.toLowerCase()) {
      case ApplicationName.DEBTOR:
        return $localize`debtorApp|Debtor App`;
      case ApplicationName.CREDITOR:
        return $localize`creditorApp|Creditor App`;
      case ApplicationName.SERVICE_BONUS:
        return $localize`serviceBonusApp|Service Bonus App`;
      case ApplicationName.PAYMENT_ADVICE:
        return $localize`paymentAdviceApp|Payment Advice App`;
      case ApplicationName.SUPPLIER_DIRECTORY:
        return $localize`supplierDirectoryApp|Supplier Directory App`;
      case ApplicationName.MARKETING_PROFIT:
        return $localize`marketingProfit|Marketing-profit`;
      case ApplicationName.VALUATION_OVERVIEW:
        return $localize`valuationOverviewApp|Valuation Overview App`;
      default:
        return appName;
      }
    })();
  }

  public getLocalizedFullAppLabel(appName: string): string {
    // eslint-disable-next-line complexity
    return (() => {
      switch (appName.toLowerCase()) {
      case FullApplicationName.DEBTOR:
        return $localize`debtorAppFull|Debtor`;
      case FullApplicationName.CREDITOR:
        return $localize`creditorAppFull|Creditor`;
      case FullApplicationName.SERVICE_BONUS_DEBTOR:
        return $localize`serviceBonusAppDebtor|Service bonus debtor`;
      case FullApplicationName.SERVICE_BONUS_CREDITOR:
        return $localize`serviceBonusAppCreditor|Service bonus creditor`;
      case FullApplicationName.PAYMENT_ADVICE:
        return $localize`paymentAdviceAppFull|Payment Advice`;
      case FullApplicationName.SUPPLIER_DIRECTORY_READ:
        return $localize`supplierDirectoryRead|Supplier Directory App`;
      case FullApplicationName.MARKETING_PROFIT_READ:
        return $localize`marketingProfit|Marketing-profit`;
      case FullApplicationName.VALUATION_OVERVIEW:
        return $localize`valuationOverviewAppFUll|Valuation Overview`;
      default:
        return appName;
      }
    })();
  }

  public getLocalizedPermissionLabel(permissionName: string): string {
    switch (permissionName.toLowerCase()) {
    case PermissionName.WRITE:
      return $localize`write|Write`;
    case PermissionName.READ:
      return $localize`read|Read`;
    case PermissionName.MARKETING_PROFIT:
      return $localize`marketingProfit|Marketing Profit`
    default:
      return permissionName;
    }
  }

  public getIcon(appName: string): Icon {
    // eslint-disable-next-line complexity
    return (() => {
      switch (appName.toLowerCase()) {
      case ApplicationName.DEBTOR:
        return Icon.DEBTOR
      case ApplicationName.CREDITOR:
        return Icon.CREDITOR;
      case ApplicationName.SERVICE_BONUS:
        return Icon.SERVICE_BONUS;
      case ApplicationName.PAYMENT_ADVICE:
        return Icon.PAYMENT_ADVICE;
      case ApplicationName.SUPPLIER_DIRECTORY:
        return Icon.SUPPLIER_DIRECTORY;
      case ApplicationName.VALUATION_OVERVIEW:
        return Icon.VALUATION;
      default:
        return Icon.COINS;
      }
    })();
  }
}
