import {
  Observable,
  OperatorFunction
} from 'rxjs';
import {
  EmployeeState,
  IEmployeeDto,
  IEmployeeRequestDto,
  IEmployeeTableService
} from './index';
import {
  IPaginationResponseDto
} from '../pagination/IPaginationResponseDto';
import {
  IexceptionHandlerService
} from '../exception/iexception-handler.service';
import {
  ISearchTermsDto
} from '../search/isearch-terms.dto';
import {
  PmInputData,
  FilterData
} from 'hagebau-coremedia';
import {
  IEmployeeDetailDto
} from './iemployee-detail.dto';
import {
  IResetUserPasswordsDto
} from './IResetUserPasswordsDto';
import {
  ValidationException
} from '../../network/api/http/api-exception';
import {
  ILdapResponseDto
} from '../ildapResponse.dto';
import {
  IBaseLocationDto,
  ILocationDto,
  ILocationRequestDto,
  IMainLocationDto
} from '../location';
import {
  IAppPermissionMinimalDto
} from '../appPermission/IAppPermissionMinimalDto';
import {
  IEmployeeBulkDeleteResponseDto
} from './iemployee-bulk-delete-response.dto';
import {
  CurrentEmployeeWithRoles
} from '../account/auth/auth.service';
import {IlocationMinimalDto} from '../location/ilocation-minimal.dto';

export abstract class IEmployeeService extends IexceptionHandlerService {
  abstract getList(requestDto: IEmployeeRequestDto): Observable<IPaginationResponseDto<IEmployeeDto>>;

  abstract getTableStructure(): IEmployeeTableService;

  abstract updateEmployeesState(uids: string[], state: EmployeeState): Observable<ILdapResponseDto>;

  abstract getEmployee(employeeNumber: string): Observable<IEmployeeDetailDto>;

  abstract GetCurrentEmployee(): Observable<CurrentEmployeeWithRoles>;

  abstract GetCurrentEmployeeMainLocations(searchTerm: string): Observable<IBaseLocationDto[]>;

  abstract preProcessSearchInput(): OperatorFunction<string, string>;

  abstract search(term: string): Observable<ISearchTermsDto>;

  abstract convertSearchResults(termsDto: ISearchTermsDto): PmInputData[];

  abstract searchFiltered(requestDto: IEmployeeRequestDto): Observable<ISearchTermsDto>;

  abstract resetUserPasswords(requestDto: IResetUserPasswordsDto): Observable<boolean>

  abstract getTranslateError(validationException: ValidationException): string;

  abstract mapToMainLocationFilterSuggestions(searchResult: IMainLocationDto[], valueField: keyof IMainLocationDto): PmInputData[];

  abstract mapToLocationFilterSuggestions(searchResult: ILocationDto[], valueField: keyof ILocationDto): PmInputData[];

  abstract getLocationFilterIdentifier(filterIdentifier: string): keyof ILocationDto;

  abstract createLocationSearchRequestDtoForFilterSuggestions(filterSnippet: FilterData): ILocationRequestDto;

  abstract sortPermissions(resultArray: IAppPermissionMinimalDto[], fromIndex: number, toIndex: number): void;

  abstract delete(ids: string[]): Observable<IEmployeeBulkDeleteResponseDto>;

  abstract transformFilterData(filterData: FilterData): FilterData;

  abstract isEmailSecurityViolatedForSuppliers(employees: IEmployeeDto[]): boolean;

  abstract isEmailSecurityViolatedForStakeholders(employees: IEmployeeDto[]): boolean;

  abstract getLocationsWithSpecialization(hgbNumbers: string[], searchInputText: string): Observable<IlocationMinimalDto[]>;
}
